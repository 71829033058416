import { Controller } from "@hotwired/stimulus";

// dealer の select 要素に刺すと store を絞り込んでくれるやつ
// dealer の value は絞り込むためだけに使ってるので dealer_code を指定
// data: { controller: "dealer-stores-select", action: "dealer-stores-select#change", stores_id: :warranty_certificate_store_id, stores: @stores.to_json }
export default class extends Controller {
  connect() {
    const selectId = this.element.dataset["storesId"];
    this.storeSelect = document.getElementById(selectId);
    if (!this.storeSelect) return;

    const initialStoreId = this.storeSelect.value;
    this.stores = JSON.parse(this.element.dataset["stores"]);
    // { store_id: Store } で持っておく
    this.selectOptions = Array.from(this.storeSelect.children).reduce(
      (hash, node) => {
        hash[node.value] = this.storeSelect.removeChild(node);
        return hash;
      },
      {},
    );

    this.#refreshStores();
    this.storeSelect.value = initialStoreId;
  }

  change() {
    if (!this.storeSelect) return;
    this.#refreshStores();
  }

  #refreshStores() {
    this.storeSelect.replaceChildren();

    this.stores
      // dealer_code が一致する Store
      .filter((x) => x.dealer_code == this.element.value)
      .forEach((x) => {
        this.storeSelect.appendChild(this.selectOptions[x.id]);
      });
    this.storeSelect.value = null;
  }
}
