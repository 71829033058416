import { Controller } from "@hotwired/stimulus";
// https://github.com/kraaden/autocomplete
import autocomplete from "autocompleter";

// フォーカス時に input が空欄の段階で表示するリスト
const defaultCars = [
  { label: "ハリアー", keywords: "" },
  { label: "プリウス", keywords: "" },
  { label: "アルファード", keywords: "" },
  { label: "アクア", keywords: "" },
  { label: "シエンタ", keywords: "" },
  { label: "エスクァイア", keywords: "" },
  { label: "ライズ", keywords: "" },
  { label: "C-HR", keywords: "" },
];

// data-controller="search-term-completion"
export default class extends Controller {
  connect() {
    this.ajax = null;
    this.url = this.element.dataset.targetUrl;
    this.list = [];
    this.noResultKeywords = "";
    this.lastResultKeywords = "";
    this.bind();
    this.setUpCompleter();
  }

  bind() {
    this.element.addEventListener("keydown", (ev) => {
      if (ev.code != "Enter") return;

      if (document.querySelector(".autocomplete > .result.selected")) {
        // autocompleter 補完候補表示中の enter での submit は中止
        ev.preventDefault();
      }
    });
  }

  refreshList(fn) {
    const keywords = this.element.value.replace(/\s+/g, " ").trim();
    // 空の場合は人気の車種リスト
    if (keywords == "") {
      if (!fn) return;
      return fn(defaultCars);
    }

    if (this.lastResultKeywords == keywords) {
      if (fn) {
        fn(this.list);
      }
      return;
    }

    // 結果がなかったキーワードに足されても何もしない
    if (
      this.noResultKeywords != "" &&
      keywords.startsWith(this.noResultKeywords)
    ) {
      if (fn) {
        fn([]);
      }
      return;
    }

    if (this.ajax != null) this.ajax.abort();

    this.ajax = $.ajax({
      type: "GET",
      url: this.url,
      data: { query: keywords },
    })
      .done((res) => {
        if (res.length > 0) {
          this.list = res;
          this.lastResultKeywords = keywords;
        } else {
          this.noResultKeywords = keywords;
        }
        if (fn) {
          fn(res);
        }
      })
      .always(() => {
        this.ajax = null;
      });
  }

  setUpCompleter() {
    autocomplete({
      input: this.element,
      minLength: 0,
      debounceWaitMs: 250,
      showOnFocus: true,
      onSelect: (item, inputfield) => {
        if (inputfield.value == item.label) return;
        inputfield.value = item.label;
        // 候補選択時に Fire
        inputfield.dispatchEvent(new Event("change"));
      },
      fetch: (text, callback) => {
        const matcher = text.replace(/\s+/g, " ").toLowerCase();
        this.refreshList((list) => {
          callback(
            list.filter((x) => {
              return x.keywords.toLowerCase().indexOf(matcher) !== -1;
            }),
          );
        });
      },
      render: (item, _) => {
        const itemElement = document.createElement("div");
        itemElement.classList.add("result");
        itemElement.classList.add("font-weight-bold");
        itemElement.innerText = item.label;
        return itemElement;
      },
    });
  }
}
