import { Client, Conversation, Message } from "@twilio/conversations";

interface HasTwilioConversationMessage {
  message: Message | null;
  hasUnread: boolean;
}

/**
 * チャット一覧表示でtwilio conversationの一覧を最終発言日時の昇順にソート
 */
export function sort<T extends HasTwilioConversationMessage>(
  conversations: T[],
): Array<T> {
  return conversations.sort((a, b) => {
    if (!a.message && !b.message) {
      return 0;
    }
    if (!a.message) {
      return 1;
    }
    if (!b.message) {
      return -1;
    }
    if (a.hasUnread && !b.hasUnread) {
      return -1;
    }
    if (!a.hasUnread && b.hasUnread) {
      return 1;
    }

    if (a.message.dateCreated.getTime() < b.message.dateCreated.getTime()) {
      return 1;
    } else {
      return -1;
    }
  });
}

/**
 * 与えられたDateから時分秒を削って年月日までの情報にしたDateを作成
 */
export function beginningOfDay(date: Date): Date {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
}

/**
 * 1 -> `01`
 * `1` -> `01`
 *
 * など
 */
export function pad(n: number | string): string {
  return String(n).padStart(2, "0");
}

export async function getUnreadMessagesCountByConversation(
  conversation: Conversation,
  identity?: string | null,
): Promise<number> {
  // 最後の既読からの未読数をチェックする
  if (
    conversation.lastReadMessageIndex !== null &&
    conversation.lastMessage?.index !== undefined &&
    identity !== null
  ) {
    if (conversation.lastReadMessageIndex < conversation.lastMessage.index) {
      return await conversation
        .getMessages(
          conversation.lastMessage.index - conversation.lastReadMessageIndex,
          conversation.lastMessage.index,
        )
        .then((paginator) => {
          // 自分の送信は未読数に含めない
          return paginator.items.filter((x) => x.author !== identity).length;
        });
    }
  }

  // 自分以外の未読カウント数で返せなかった場合はここで返す
  const unreadMessagesCount = await conversation.getUnreadMessagesCount();
  if (unreadMessagesCount !== null) {
    return unreadMessagesCount;
  }

  // 未読が null の場合はまだ一度も conversation を開いていない
  // メッセージ全体の自分以外の件数をカウントして返す
  const messageCount = await conversation.getMessagesCount();
  if (messageCount > 0) {
    const messages = await conversation.getMessages();
    return messages.items.filter((x) => x.author !== identity).length;
  }

  // 件数カウントで返せなかった場合は、メッセージ件数全体を返す
  return messageCount;
}

/**
 *
 * @param client Twilio クライアント
 * @param sids Conversation SID
 * @param identity メッセージ送信者識別文字
 * @returns Conversations の未読数合計
 */
export async function getUnreadMessagesCount(
  client: Client,
  sids: string[],
  identity?: string | null,
): Promise<number> {
  return sids.reduce(async (accumulator, sid) => {
    const conversation = await client.getConversationBySid(sid);
    const unread = await getUnreadMessagesCountByConversation(
      conversation,
      identity,
    );
    const current = await accumulator;
    return unread + current;
  }, Promise.resolve(0));
}

export function toDateString(date: Date): string {
  const today = beginningOfDay(new Date());
  const beginning = beginningOfDay(date);
  if (today.getTime() === beginning.getTime()) {
    return `${pad(date.getHours())}:${pad(date.getMinutes())}`;
  }

  today.setDate(today.getDate() - 1);
  if (today.getTime() === beginning.getTime()) {
    return `昨日 ${pad(date.getHours())}:${pad(date.getMinutes())}`;
  }

  return `${date.getFullYear()}/${pad(date.getMonth() + 1)}/${pad(
    date.getDate(),
  )} ${pad(date.getHours())}:${pad(date.getMinutes())}`;
}
