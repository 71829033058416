import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["modal", "form"];
  static values = { url: String };

  /**
   * 削除確認用のモーダルを表示する
   */
  showConfirmModal(event) {
    this.removeButton = event.currentTarget;
    this.messageId = this.removeButton.dataset.messageId;
    const url = `${this.urlValue}/${this.messageId}`;
    this.formTarget.action = url;

    window.BootstrapWrapper.showModal(this.modalTarget);
  }

  /**
   * メッセージを削除する
   */
  removeMessage() {
    const formData = new FormData(this.formTarget);

    // 削除ボタンを無効化
    this.removeButton.disabled = true;

    fetch(this.formTarget.action, {
      method: "DELETE",
      body: formData,
    }).then((response) => {
      if (response.ok) {
        this.#hideMessage();
      } else {
        console.error(`削除エラー (${response.status})`);

        // 無効化を戻しておく
        this.removeButton.disabled = false;
      }
    });
  }

  /**
   * メッセージをアニメーション付けて非表示にする
   */
  #hideMessage() {
    const message = document.getElementById(
      `talkroom_message_${this.messageId}`,
    );

    // TODO: CSS でやる. turbo-frame の下に div とか
    message.style.display = "block";
    message.style.overflow = "hidden";
    message.style.height = "auto";
    message.style.transitionDuration = "250ms";
    message.style.transitionProperty = "height";
    const height = message.offsetHeight;
    message.style.height = `${height}px`;
    // ここでUAに高さを認識させるとアニメーションが効く
    window.getComputedStyle(message).height;
    message.style.height = 0;
  }
}
