import Rails from "@rails/ujs";
import { Turbo } from "@hotwired/turbo-rails";
import * as ActiveStorage from "@rails/activestorage";
import "channels";

Rails.start();
ActiveStorage.start();
Turbo.setProgressBarDelay(250);

// turbo での画面遷移もログに残す。
import { setUpTurboDriveLog, setUpTurboFrameLog } from "../shared/turbo_log";
setUpTurboDriveLog();
setUpTurboFrameLog();

import { TwilioConnection } from "../shared/TwilioConnection";
window.TwilioConnection = new TwilioConnection();

const jQuery = require("jquery");
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

// jquery を使うライブラリはここで読み込む。
require("bootstrap");

import { BootstrapWrapperBootstrap4 } from "../shared/BootstrapWrapperBootstrap4";
window.BootstrapWrapper = new BootstrapWrapperBootstrap4();

// drawer menu http://git.blivesta.com/drawer/
require("jquery-drawer");

require("@fortawesome/fontawesome-free/js/all");
// body 更新時のチラつき抑制
// https://fontawesome.com/v5.15/how-to-use/on-the-web/using-with/turbolinks
window.FontAwesome.config.mutateApproach = "sync";

// stimulus
import { Application } from "@hotwired/stimulus";
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers";
const application = Application.start();
application.load(
  definitionsFromContext(
    require.context("../controllers/shared", true, /\.js$/),
  ),
);
application.load(
  definitionsFromContext(require.context("../controllers/user", true, /\.js$/)),
);

// Support component names relative to this directory:
const componentRequireContext = require.context("components", true);
import ReactRailsUJS from "react_ujs";
ReactRailsUJS.useContext(componentRequireContext);
