import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "imageModal",
    "imagePreview",
    "pdfModal",
    "fileName",
    "pdfLink",
    "tokenForm",
  ];

  /**
   * モーダルを表示する
   *   image: 画像プレビューモーダル
   *   pdf: PDFを開く確認用モーダル
   */
  showModal(event) {
    const fileType = event.currentTarget.dataset.fileType;
    const fileName = event.currentTarget.dataset.filename;
    const url = event.currentTarget.dataset.url;

    if (fileType === "pdf") {
      this.fileNameTarget.textContent = fileName;

      const fileId = event.currentTarget.dataset.fileId;
      this.#getTokenAfterShowModal(fileId);
    } else {
      this.imagePreviewTarget.src = url;
      window.BootstrapWrapper.showModal(this.imageModalTarget);
    }
  }

  #getTokenAfterShowModal(fileId) {
    // トークン発行を行う form を特定する
    const form = this.tokenFormTargets.find(
      (target) => target.dataset.fileId === fileId,
    );

    // ファイルアクセスに必要なトークン発行
    fetch(form.action, {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          console.error(`トークン取得に失敗 (${response.status})`);
        }
        return response.json();
      })
      .then((data) => {
        // トークンを付けたURLにする
        this.pdfLinkTarget.href = `${data.url}`;
        window.BootstrapWrapper.showModal(this.pdfModalTarget);
      })
      .catch((error) => {
        console.error("エラーが発生しました: ", error);
        alert("ファイルの読み込みに失敗しました。再試行してください。");
      });
  }
}
