import { Controller } from "@hotwired/stimulus";

/**
 * data-controller="checkbox-all"
 *
 * item すべてが選択されていたら all チェックボックスも選択状態に、
 * 1 つでも選択されていなかったら all チェックボックスは非選択状態にする。
 * 逆に、 all チェックボックスのチェックを外したら item すべてのチェックを外す。
 * 連動して変わったチェックボックスの click や change イベントは飛ばないので注意。
 *
 * all チェックボックスに `data-checkbox-all-target="all"`
 * 他のチェックボックスに `data-checkbox-all-target="item"`
 */
export default class extends Controller {
  static targets = ["all", "item"];

  connect() {
    /** @type HTMLInputElement */
    const allTarget = this.allTarget;
    /** @type [HTMLInputElement] */
    const itemTargets = this.itemTargets;

    allTarget.checked = itemTargets.every((item) => item.checked);
    allTarget.addEventListener("click", this.#clickAll);
    itemTargets.forEach((item) =>
      item.addEventListener("click", this.#clickItem),
    );
  }

  disconnect() {
    /** @type HTMLInputElement */
    const allTarget = this.allTarget;
    /** @type [HTMLInputElement] */
    const itemTargets = this.itemTargets;

    allTarget.removeEventListener("click", this.#clickAll);
    itemTargets.forEach((item) =>
      item.removeEventListener("click", this.#clickItem),
    );
  }

  #clickAll = () => {
    /** @type HTMLInputElement */
    const allTarget = this.allTarget;
    /** @type [HTMLInputElement] */
    const itemTargets = this.itemTargets;

    itemTargets.forEach((item) => {
      item.checked = allTarget.checked;
    });
  };

  #clickItem = () => {
    /** @type HTMLInputElement */
    const allTarget = this.allTarget;
    /** @type [HTMLInputElement] */
    const itemTargets = this.itemTargets;

    allTarget.checked = itemTargets.every((item) => item.checked);
  };
}
