import { Controller } from "@hotwired/stimulus";
import { getConversationMessageHtml } from "../../components/getConversationMessageHtml";

/**
 * 指定したエレメントのテキストのURLにaタグを入れる
 *
 * 使用例:
 * ```
 * <p data-controller="enable-link">
 *   https://www.google.com/
 *   リンクにaタグがつく
 * </p>
 * ```
 */
export default class extends Controller {
  connect() {
    this.element.innerHTML = getConversationMessageHtml(
      this.element.textContent,
    ).__html;
  }
}
