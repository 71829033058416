import { Controller } from "@hotwired/stimulus";

/**
 * どれかひとつをチェックしないとsubmitできないようにする
 */
export default class extends Controller {
  static targets = ["checkbox", "submit"];

  connect() {
    this.check();
  }

  check() {
    const noChecked = this.checkboxTargets.every((x) => !x.checked);
    this.submitTarget.disabled = noChecked;
  }
}
