import { Controller } from "@hotwired/stimulus";

/**
 * bootstrap モーダルを開くアンカーのリンク先をモーダル内の iframe で開く
 *
 * <section id="contact-modal" class="modal" data-controller="contact-modal">
 * <iframe data-contact-modal-target="iframe"></iframe>
 */
export default class extends Controller {
  static targets = ["iframe", "title"];

  connect() {
    // bootstrap modal triggers
    this.triggers = document.querySelectorAll(
      `[data-target="#${this.element.id}"][data-toggle=modal]`,
    );
    this.triggers.forEach((el) => {
      el.addEventListener("click", this.#onShow);
    });

    window.addEventListener("message", this.#onMessage);
    this.iframeTarget.addEventListener("load", this.#onIframeLoaded);
  }

  disconnect() {
    this.triggers.forEach((el) => {
      el.removeEventListener("click", this.#onShow);
    });
    window.removeEventListener("message", this.#onMessage);
    this.iframeTarget.removeEventListener("load", this.#onIframeLoaded);
  }

  #onShow = (ev) => {
    // `?iframe=true` を追加して iframe 内で開く。
    const targetUrl = new URL(ev.currentTarget.href);
    targetUrl.searchParams.set("iframe", "true");
    const target = targetUrl.toString();

    if (this.iframeTarget.src == target) return;

    this.iframeTarget.height = "0";
    this.element.classList.add("invisible");
    this.iframeTarget.src = target;
  };

  #onMessage = (ev) => {
    // 他のサイトからメッセージを送りつけられる可能性があるけど、
    // 表示を更新するだけなので、セキュリティ的に問題ない。
    if (ev.data.type === "iframeConnected") {
      this.#updateIframe();
    }
  };

  #onIframeLoaded = () => {
    // 最初のページでは基本的に #onMessage が先に呼ばれるので、何もしない。
    // それ以降のページでは invisible にならないので、何もしない。
    // 最初のページで #onMessage が呼ばれない場合 (エラーページなど) に対応する。
    if (this.element.classList.contains("invisible")) {
      this.#updateIframe();
    }
  };

  #updateIframe() {
    const contentDocument = this.iframeTarget.contentDocument;
    this.iframeTarget.height = contentDocument.body.scrollHeight;

    const title = contentDocument.querySelector("input[name=カテゴリ]")?.value;
    if (title) {
      this.titleTarget.textContent = title;
    }
    this.element.classList.remove("invisible");
  }
}
