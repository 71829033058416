import { Controller } from "@hotwired/stimulus";
import PinchZoom from "pinch-zoom-js";

export default class extends Controller {
  connect() {
    this.#setUpModal();
  }

  async #setUpModal() {
    const exhibitedImagesContainer = document.querySelector(
      ".exhibitedImagesContainer",
    );
    const modalArea = exhibitedImagesContainer.querySelector(".modalArea");

    // モーダルを展開する
    const imageModal = () => {
      if (!modalArea) return;

      const modalController = () => {
        // `modalArea` に `modalArea--show` クラスをトグル
        modalArea.classList.toggle("modalArea--show");

        // モーダル展開時に `modalArea__inner` の高さを設定
        if (modalArea.classList.contains("modalArea--show")) {
          const headerHeight = document.querySelector(".navbar").offsetHeight;
          const ctaHeight = document.querySelector(
            ".exhibited-car-actions",
          ).offsetHeight;
          const modalAreaInner = modalArea.querySelector(".modalArea__inner");
          const modalAreaInnerHeight =
            window.innerHeight - headerHeight - ctaHeight;

          modalAreaInner.style.height = `${modalAreaInnerHeight}px`;

          imagePinching();
        }

        // モーダル展開時のスクロール制御
        document.body.style.overflow = modalArea.classList.contains(
          "modalArea--show",
        )
          ? "hidden"
          : "auto";
      };

      // モーダル展開時の画像 index に応じたスクロール処理
      const modalScroll = (t) => {
        const index = t.dataset.swiperSlideIndex;
        const modalAreaInnerItems = modalArea.querySelector(
          ".modalArea__inner__items",
        );
        const targetHeight = modalAreaInnerItems.querySelectorAll(
          ".modalArea__inner__item",
        )[index].offsetHeight;
        const gap = 16;

        modalAreaInnerItems.scrollTop = index * gap + index * targetHeight;
      };

      // クリックイベントを設定
      exhibitedImagesContainer.addEventListener("click", (e) => {
        const clickedElm = e.target.closest(".modal-list");

        if (!clickedElm) return;

        // クリックイベントを無効化
        const linkElm = e.target.closest("a");
        if (linkElm) e.preventDefault();

        modalController();

        setTimeout(() => {
          modalScroll(clickedElm);
        }, 0);
      });

      // モーダルを閉じる
      const closeBtn = modalArea.querySelector(".modalArea__close");

      closeBtn.addEventListener("click", () => {
        modalArea.classList.remove("modalArea--show");
        document.body.style.overflow = "auto";
      });
    };

    // SP でモーダル内の画像をピンチイン・アウトできるようにする
    const imagePinching = () => {
      const el = modalArea.querySelectorAll(".modalArea__inner__image .image");

      const adjustHeight = (imgElement) => {
        setTimeout(() => {
          const imageHeight = imgElement.getBoundingClientRect().height;
          const container = imgElement.closest(".pinch-zoom-container");
          if (container && imageHeight > 0) {
            container.style.height = `${imageHeight}px`;
          }
        }, 0);
      };

      el.forEach((item) => {
        new PinchZoom(item, {});

        if (item.complete) {
          adjustHeight(item);
        } else {
          item.addEventListener("load", () => {
            adjustHeight(item);
          });
        }
      });
    };

    imageModal();
  }
}
