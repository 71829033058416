import interact from "interactjs";

/**
 * 画像の上にスタンプを乗せてジェスチャーで動かせる canvas を作る。
 * ナンバー隠し用。
 */
export class StampCanvas {
  /**
   * @param {CanvasImageSource} baseImage
   * @param {Number} canvasWidth
   * @param {Number} canvasHeight
   * @param {CanvasImageSource} stampImage
   * @param {CanvasImageSource} uiUtampImage
   * @param {Number} initStampWidth
   * @param {Number} initStampHeight
   */
  constructor(
    baseImage,
    canvasWidth,
    canvasHeight,
    stampImage,
    uiStampImage,
    initStampWidth,
    initStampHeight,
  ) {
    this._baseImage = baseImage;
    this._stampImage = stampImage;
    this._uiStampImage = uiStampImage;
    this._stampWidth = initStampWidth;
    this._stampHeight = initStampHeight;
    this.stampScale = 1;
    this.stampX = canvasWidth / 2;
    this.stampY = canvasHeight / 2;
    this.canvas = this.createCanvas(canvasWidth, canvasHeight);
    this.setUpInteract();
  }

  get stampScale() {
    return this._stampScale;
  }

  set stampScale(stampScale) {
    this._stampScale = stampScale;
    if (this._onChangeScale) {
      this._onChangeScale(this._stampScale);
    }
  }

  /**
   * @callback onChangeScale
   * @param {number} scale
   * @returns {void}
   */
  /**
   * @param {onChangeScale} onChangeScale
   */
  set onChangeScale(onChangeScale) {
    this._onChangeScale = onChangeScale;
  }

  /** 移動 UI 用のスタンプで canvas を更新 */
  drawUi() {
    this._draw(this._uiStampImage);
  }

  /** 保存用のスタンプで canvas を更新 */
  drawResult() {
    this._draw(this._stampImage);
  }

  _draw(stampImage) {
    const canvas = this.canvas;
    const context = canvas.getContext("2d", { alpha: false });

    context.save();
    context.fillStyle = "#E5E7E9"; // 背景色
    context.fillRect(0, 0, canvas.width, canvas.height);
    context.translate(
      Math.floor(canvas.width / 2),
      Math.floor(canvas.height / 2),
    );
    context.drawImage(
      this._baseImage,
      -this._baseImage.width / 2,
      -this._baseImage.height / 2,
    );
    context.restore();

    context.save();
    const width = Math.floor(this._stampWidth * this.stampScale);
    const height = Math.floor(this._stampHeight * this.stampScale);
    context.translate(Math.floor(this.stampX), Math.floor(this.stampY));
    context.drawImage(stampImage, -width / 2, -height / 2, width, height);
    context.restore();
  }

  createCanvas(width, height) {
    const canvas = document.createElement("canvas");
    canvas.width = width;
    canvas.height = height;
    return canvas;
  }

  setUpInteract() {
    interact(this.canvas)
      .draggable({
        listeners: {
          move: (event) => {
            const dx = (event.dx / event.rect.width) * this.canvas.width;
            const dy = (event.dy / event.rect.height) * this.canvas.height;
            this.stampX += dx;
            this.stampY += dy;
            this.drawUi();
          },
        },
      })
      .gesturable({
        onmove: (event) => {
          this.stampScale *= 1 + event.ds;
          this.drawUi();
        },
      });
  }
}
