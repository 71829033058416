import { Controller } from "@hotwired/stimulus";
import Rails from "@rails/ujs";

// 親コンポーネント
// <div class="container" data-controller="search-filter">
//   フォーム
//   <form data-search-filter-target="form">
//   結果表示エリア (optional)
//   <div data-search-filter-target="results">
//   フォームリセット
//   <input type="reset" data-taget="search-filter.reset">
//   結果表示ボタン. 表示エリア(resultsTarget)がない場合は車両一覧へのリンク
//   <a data-dismiss="modal" data-search-filter-target="showResults resultButtonPresent">
//   空だった時の結果表示ボタン
//   <div data-search-filter-target="resultButtonBlank">
// </div>
export default class extends Controller {
  static targets = [
    "form",
    "results",
    "reset",
    "showResults",
    "resultButtonBlank",
    "resultButtonPresent",
    "resultCountOutput",
  ];

  initialize() {
    this.serializer = new XMLSerializer();
  }

  connect() {
    if (!this.hasFormTarget) return;

    this.url = this.formTarget.action;
    this.initializeState();
    this.bind();
  }

  initializeState() {
    if (!this.hasResultsTarget) return;

    history.replaceState(
      this.serializer.serializeToString(this.resultsTarget),
      null,
      location.url,
    );
  }

  bind() {
    // on submit
    this.formTarget.addEventListener("submit", (ev) => {
      ev.preventDefault();
      this.submit();
    });
    // form changes
    this.formTarget.querySelectorAll(".search-param").forEach((x) => {
      x.addEventListener("change", () => {
        this.submit();
      });
    });
    // input[type=reset]
    this.bindResetForm();

    // 結果表示エリアが無い場合は結果表示ボタンを通常のリンクにして終了
    if (!this.hasResultsTarget) {
      this.showResultsTargets.forEach((x) => {
        x.dataset.dismiss = null;
      });
      return;
    }
    // pagination links
    this.bindPaginationEvents();
    // on history-back
    window.onpopstate = (ev) => {
      if (!ev.state || !ev.state.result) return;

      ev.preventDefault();
      this.replaceWithState(ev.state);
    };
  }

  // state から状態を復元
  replaceWithState(state) {
    this.resultsTarget.innerHTML = state.result;
    // ページャーのイベントは剥がれるので再設定
    this.bindPaginationEvents();
    // 検索オプションはモーダル外で変更できるので再設定
    this.formTarget["sort_by"].value = state.values["sort_by"];
    this.formTarget["exclude_soldout"].checked =
      state.values["exclude_soldout"];

    const title = document.getElementById("search-title");
    if (title) {
      title.textContent = state.title;
    }
    const breadcrumb = document.querySelector(".breadcrumb");
    if (breadcrumb) {
      breadcrumb.innerHTML = state.breadcrumb;
    }
    const canonicalUrl = document.getElementById("canonical_link");
    if (canonicalUrl) {
      canonicalUrl.href = state.canonicalUrl;
    }
  }

  bindPaginationEvents() {
    this.resultsTarget.querySelectorAll("a.page-link").forEach((x) => {
      x.addEventListener("click", (ev) => {
        const url = ev.currentTarget.href;
        if (!url) return;
        ev.preventDefault();
        this.resultsLoading();

        Rails.ajax({
          type: "GET",
          url: url,
          beforeSend: (xhr) => this.beforeSend(xhr),
          success: (html, _, xhr) => this.replaceSearchResult(html, xhr),
          error: null,
          complete: () => {
            this.xhr = null;
          },
        });
      });
    });
  }

  // クエリ付きURLに直に来た場合はパラメーターが初期値になるので全部舐める
  bindResetForm() {
    this.resetTargets.forEach((x) => {
      x.addEventListener("click", (ev) => {
        ev.preventDefault();

        this.formTarget
          .querySelectorAll(
            "input[type=text].search-param, select.search-param",
          )
          .forEach((f) => (f.value = ""));
        this.formTarget
          .querySelectorAll("input[type=checkbox].search-param")
          .forEach((f) => (f.checked = false));
        this.submit();
      });
    });
  }

  submit() {
    const data = new FormData(this.formTarget);
    const segment = new URL(location.href).searchParams.get("segment") || "";
    data.append("segment", segment);
    const params = new URLSearchParams(data).toString();
    this.formTarget.classList.add("searching");
    this.resultsLoading();

    // parameters: https://github.com/rails/rails/blob/v6.1.7.1/actionview/app/assets/javascripts/rails-ujs/utils/ajax.coffee#L19-L26
    Rails.ajax({
      type: "GET",
      url: this.url,
      data: params,
      beforeSend: (xhr) => this.beforeSend(xhr),
      success: (html, _, xhr) => this.replaceSearchResult(html, xhr),
      error: null,
      complete: () => {
        this.xhr = null;
        this.formTarget.classList.remove("searching");
      },
    });
  }

  /**
   * 前回の検索条件で検索する
   * @param {Event} event
   */
  prevFilter(event) {
    this.formTarget.classList.add("searching");
    this.resultsLoading();

    // parameters: https://github.com/rails/rails/blob/v6.1.7.1/actionview/app/assets/javascripts/rails-ujs/utils/ajax.coffee#L19-L26
    Rails.ajax({
      type: "GET",
      url: event.target.href,
      beforeSend: (xhr) => this.beforeSend(xhr),
      success: (html, _, xhr) => this.replaceSearchResult(html, xhr),
      error: null,
      complete: () => {
        this.xhr = null;
        this.formTarget.classList.remove("searching");
      },
    });
  }

  beforeSend(xhr) {
    if (this.xhr) {
      this.xhr.abort();
    }
    this.xhr = xhr;
    // false だと送信しない
    return true;
  }

  replaceSearchResult(html, xhr) {
    this.updateTotalCount(html);
    // 結果表示エリアがなければボタンにリンク先を設定して終了
    if (!this.hasResultsTarget) {
      this.setShowResultsURL(xhr.responseURL);
      return;
    }

    const node = html.getElementById(this.resultsTarget.id);
    this.resultsTarget.replaceWith(node);
    const state = {
      result: node.innerHTML,
      values: this.getSearchOptionValues(),
      title: null,
      breadcrumb: null,
      canonicalUrl: null,
    };

    // 検索タイトルを更新
    const titleNew = html.getElementById("search-title");
    if (titleNew) {
      state.title = titleNew.textContent;
      const title = document.getElementById("search-title");
      if (title) {
        title.textContent = titleNew.textContent;
      }
    }
    // パンクズを更新
    const breadcrumbNew = html.querySelector(".breadcrumb");
    if (breadcrumbNew) {
      state.breadcrumb = breadcrumbNew.innerHTML;
      const breadcrumb = document.querySelector(".breadcrumb");
      if (breadcrumb) {
        breadcrumb.replaceWith(breadcrumbNew);
      }
    }
    // canonical url を更新 (クローラーはフォームいじらないと思うけど指摘あったし一応)
    const canonicalUrlNew = html.getElementById("canonical_link");
    if (canonicalUrlNew) {
      state.canonicalUrl = canonicalUrlNew.href;
      const canonicalUrl = document.getElementById("canonical_link");
      if (canonicalUrl) {
        canonicalUrl.href = canonicalUrlNew.href;
      }
    }
    this.bindPaginationEvents();

    history.pushState(state, null, xhr.responseURL);
  }

  getSearchOptionValues() {
    return {
      sort_by: this.formTarget["sort_by"].value,
      exclude_soldout: this.formTarget["exclude_soldout"].checked,
    };
  }

  updateTotalCount(html) {
    const el = html.getElementById("exhibited-cars-total-count");
    const blank = this.resultButtonBlankTarget;
    const present = this.resultButtonPresentTarget;

    if (!el) {
      // 検索結果なし
      blank.classList.remove("d-none");
      present.classList.add("d-none");
    } else {
      blank.classList.add("d-none");
      present.classList.remove("d-none");
      this.resultCountOutputTarget.textContent = el.textContent;
    }
  }

  // 検索結果表示ボタンにリンク先を設定, 表示エリアがない時用
  setShowResultsURL(url) {
    this.showResultsTargets.forEach((x) => {
      x.href = url;
    });
  }

  resultsLoading() {
    if (!this.hasResultsTarget) return;

    $("html").animate({ scrollTop: 0 }, 128);
    this.resultsTarget.style.opacity = 0.6;
  }
}
