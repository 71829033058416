import { Controller } from "@hotwired/stimulus";
import { add, format, parse, compareAsc } from "date-fns";

/**
 * TFC分割払いの概算見積表示
 *   paymentSplit: 支払条件(1回 or 分割)
 *   paymentPlaceholder: 支払条件を表示する場所
 *   paymentSplitSection: 分割を選択した場合に表示する項目
 *   oneTimePaymentSection": 1回払いを選択した場合に表示する項目
 *   priceAtTime: 本体価格
 *   optionPrice: オプション料金
 *   optionPricePlaceholder: オプション料金を表示する場所
 *   initialPayment: 追加頭金
 *   sumInitialPaymentPlaceholder: 頭金合計を表示する場所
 *   bonusPayment: ボーナス支払設定
 *   bonusPaymentSection: ボーナス支払ありにした場合に表示する項目
 *   loanResult: 見積結果の表示
 *   numberOfPayments: 支払回数
 *   paymentPeriod: 支払期間を表示する場所
 *   initialPricePlaceholder: 初回支払額を表示する場所
 *   monthlyPricePlaceholder: 月々支払額を表示する場所
 *   principalPlaceholder: 割賦元金を表示する場所
 *   creditFeePlaceholder: 分割払手数料を表示する場所
 *   sumCreditFeePricePlaceholder: 分割払金合計を表示する場所
 *   sumPaymentPlaceholder: 支払総額を表示する場所
 *   bonusPaymentPrice: ボーナス月支払額
 *   bonusPayment1Month: 夏期ボーナス月
 *   bonusPayment2Month: 冬期ボーナス月
 *   bonusPaymentStartDatePlaceholder: ボーナス支払開始年月を表示する場所
 *   bonusPaymentMonthPlaceholder: ボーナス支払月を動的に表示する場所
 *   bonusPricePlaceholder: ボーナス月支払額を表示する場所
 *   confirmButton: 確認用モーダルを呼び出すボタン
 *   interestRate: 実質年率の値
 *   paymentStartDate: 支払開始日
 *   skipFirstBonusMonth: 最初のボーナス月をスキップする
 *   skipFirstBonusMonthLabel: 最初のボーナス月をスキップするチェックボックスのラベル
 *   bonusPaymentPriceError: ボーナス支払額がエラーの場合
 *   initialPaymentPriceError: 頭金支払額がエラーの場合
 *   changeInPaymentStartDate: 支払い開始日の変更の有無（admin画面の場合）
 */
export default class extends Controller {
  static targets = [
    "paymentSplit",
    "paymentPlaceholder",
    "paymentSplitSection",
    "oneTimePaymentSection",
    "priceAtTime",
    "optionPrice",
    "optionPricePlaceholder",
    "initialPayment",
    "sumInitialPaymentPlaceholder",
    "bonusPayment",
    "bonusPaymentSection",
    "loanResult",
    "numberOfPayments",
    "paymentPeriod",
    "initialPricePlaceholder",
    "monthlyPricePlaceholder",
    "principalPlaceholder",
    "creditFeePlaceholder",
    "sumCreditFeePricePlaceholder",
    "sumPaymentPlaceholder",
    "bonusPaymentPrice",
    "bonusPayment1Month",
    "bonusPayment2Month",
    "bonusPaymentStartDatePlaceholder",
    "bonusPaymentMonthPlaceholder",
    "bonusPricePlaceholder",
    "confirmButton",
    "interestRate",
    "paymentStartDate",
    "skipFirstBonusMonth",
    "skipFirstBonusMonthLabel",
    "bonusPaymentPriceError",
    "initialPaymentPriceError",
    "mycarLoan",
    "changeInPaymentStartDate",
  ];

  static values = { savedPaymentStartDate: String };

  connect() {
    this.sumAmount();
  }

  /**
   * 諸経費を頭金に設定する
   */
  setOptionAsInitialPayment() {
    // 諸経費に必要な金額の不足分を足す
    const inputInitialPaymentPrice = parseInt(
      this.initialPaymentTarget.value || 0,
    );
    const addPrice = this.sumOptionPrice - inputInitialPaymentPrice;

    // 不足分が無い場合はスキップ
    if (addPrice < 0) {
      return;
    }

    this.initialPaymentTarget.value = inputInitialPaymentPrice + addPrice;
    this.sumAmount();
  }

  /**
   * 分割払い設定の金額を計算する
   */
  sumAmount() {
    this.#sumOptionPrice();
    this.#setActivePaymentSplitSection();
    this.#sumInitialPayment();
    this.#setActiveBonusPaymentSection();
    this.#loanSimulation();
  }

  /**
   * 分割払い設定の表示切り替え
   */
  #setActivePaymentSplitSection() {
    // オプション料金の計算が出来ない場合は中断する
    if (isNaN(this.sumOptionPrice)) {
      this.paymentSplitSectionTargets.forEach((target) =>
        target.classList.add("d-none"),
      );
      return;
    }

    if (this.paymentSplitTarget.checked) {
      // 分割支払に関連するコンテンツを表示する
      this.paymentSplitSectionTargets.forEach((target) =>
        target.classList.remove("d-none"),
      );
      this.paymentPlaceholderTarget.textContent = `分割払い（${this.numberOfPaymentsTarget.value}回）`;

      // 銀行1回払いに関連するコンテンツを非表示にする
      this.oneTimePaymentSectionTargets.forEach((target) =>
        target.classList.add("d-none"),
      );
    } else {
      // 分割支払に関連するコンテンツを非表示にする
      this.paymentSplitSectionTargets.forEach((target) =>
        target.classList.add("d-none"),
      );

      // 銀行1回払いに関連するコンテンツを表示する
      this.oneTimePaymentSectionTargets.forEach((target) =>
        target.classList.remove("d-none"),
      );
      this.paymentPlaceholderTarget.textContent = "銀行振込（1回払い）";
    }
  }

  /**
   * 諸費用・オプション料金を計算する
   */
  #sumOptionPrice() {
    const prices = this.optionPriceTargets.map((target) => {
      if (target.type == "checkbox") {
        return target.checked ? Number(target.dataset.price) : 0;
      } else {
        return Number(target.dataset.price);
      }
    });

    this.sumOptionPrice = prices.reduce((sum, price) => sum + price);
    this.optionPricePlaceholderTargets.forEach((target) => {
      target.textContent = isNaN(this.sumOptionPrice)
        ? "---"
        : this.sumOptionPrice.toLocaleString();
    });
  }

  /**
   * 頭金合計を計算する
   */
  #sumInitialPayment() {
    // オプション料金が計算されていない場合は中断
    if (!this.sumOptionPrice) {
      return;
    }

    let initialPayment = Number(this.initialPaymentTarget.value);
    if (initialPayment < 0) {
      this.initialPaymentTarget.value = 0;
      initialPayment = 0;
    }
    this.sumInitialPayment = initialPayment;

    this.sumInitialPaymentPlaceholderTargets.forEach(
      (target) =>
        (target.textContent = this.sumInitialPayment.toLocaleString()),
    );
  }

  /**
   * ボーナス支払設定の表示切り替え
   */
  #setActiveBonusPaymentSection() {
    this.bonusPaymentSectionTargets.forEach((target) => {
      if (this.bonusPaymentTarget.checked) {
        target.classList.remove("d-none");
      } else {
        target.classList.add("d-none");
      }
    });
  }

  /**
   * ローンシミュレーションして結果を表示する
   */
  #loanSimulation() {
    this.loanResultTarget.classList.add("d-none");
    this.currentParams = "";

    // 金額表示を計算中はマスキングする
    this.sumPaymentPlaceholderTargets.forEach(
      (target) => (target.textContent = "---"),
    );
    this.monthlyPricePlaceholderTargets.forEach(
      (target) => (target.textContent = "---"),
    );

    // 分割支払を選択していない場合は中断する
    // オプション料金の計算が出来ない場合も中断する
    if (!this.paymentSplitTarget.checked || isNaN(this.sumOptionPrice)) {
      this.confirmButtonTarget.disabled = isNaN(this.sumOptionPrice);
      return;
    }

    this.confirmButtonTarget.disabled = true;
    this.#setBonusPaymentMonth();

    // 割賦元金を計算する
    // 本体価格 + 諸費用・オプション合計 - 頭金
    const installmentPrincipal =
      Number(this.priceAtTimeTarget.value) +
      this.sumOptionPrice -
      Number(this.initialPaymentTarget.value);
    this.principalPlaceholderTargets.forEach((target) => {
      target.textContent = installmentPrincipal.toLocaleString();
      target.value = parseInt(installmentPrincipal);
    });

    let bonusPaymentPrice = "";
    let bonusPayment1Month = "none";
    let bonusPayment2Month = "none";

    // ボーナス支払を設定している場合
    if (this.bonusPaymentTarget.checked) {
      // ボーナス支払額が設定されていない場合は中断
      if (this.bonusPaymentPriceTarget.value < 3000) {
        this.bonusPaymentPriceErrorTarget.textContent =
          "支払額は3,000円以上を指定してください。";
        return;
      }

      // ボーナス支払額が割賦元金を超えている場合は中断
      if (this.bonusPaymentPriceTarget.value > installmentPrincipal) {
        this.bonusPaymentPriceErrorTarget.textContent = "支払額が多すぎます。";
        return;
      }

      this.bonusPaymentPriceErrorTarget.textContent = "";

      // ボーナス月が設定されていない場合は中断
      if (
        this.bonusPayment1MonthTarget.value == "none" ||
        this.bonusPayment2MonthTarget.value == "none"
      ) {
        return;
      }

      // ボーナス月が同じ値の場合は中断
      if (
        this.bonusPayment1MonthTarget.value ==
        this.bonusPayment2MonthTarget.value
      ) {
        return;
      }

      bonusPaymentPrice = this.bonusPaymentPriceTarget.value;
      bonusPayment1Month = this.bonusPayment1MonthTarget.value;
      bonusPayment2Month = this.bonusPayment2MonthTarget.value;
    }

    this.#setPaymentEndYearMonth();

    // マイナスになる場合は頭金が多すぎるので中断
    if (installmentPrincipal < 0) {
      this.initialPaymentPriceErrorTarget.textContent =
        "頭金の指定額が多すぎます。";
      return;
    } else {
      this.initialPaymentPriceErrorTarget.textContent = "";
    }

    const params = {
      principal: installmentPrincipal,
      interest_rate_permill: this.interestRateTarget.value,
      number_of_payments: this.numberOfPaymentsTarget.value,
      payment_start_date: this.paymentStartDateTarget.value,
      bonus_payment1_price: bonusPaymentPrice,
      bonus_payment2_price: bonusPaymentPrice,
      bonus_payment1_month: bonusPayment1Month,
      bonus_payment2_month: bonusPayment2Month,
      skip_first_bonus_month: this.skipFirstBonusMonthTarget.checked,
    };
    const currentParams = new URLSearchParams(params).toString();
    this.currentParams = currentParams;

    this.#getLoanSimulatorResult(currentParams).then((json) => {
      // リクエストした時と状況が変わっていたらスキップする。
      if (this.currentParams != currentParams) {
        return;
      }

      // エラーを含む場合は中断する。
      if (json["errors"]) {
        console.error(json["errors"]);
        return;
      }

      // 初回、毎月の支払額が3000円未満になる場合は中断する
      if (json["initial_price"] < 3000 || json["monthly_price"] < 3000) {
        // TODO: 一旦適当な位置にエラーを出してるけど、エラー内容や位置は調整したい
        this.bonusPaymentPriceErrorTarget.textContent =
          "支払額の指定が多すぎます。";
        return;
      }

      // 初回支払額
      this.initialPricePlaceholderTarget.textContent =
        json["initial_price"].toLocaleString();

      // 月々支払額
      this.monthlyPricePlaceholderTargets.forEach(
        (target) =>
          (target.textContent = json["monthly_price"].toLocaleString()),
      );

      // 分割手数料
      this.creditFeePlaceholderTarget.textContent =
        json["credit_fee"].toLocaleString();

      // 分割払金合計
      // 割賦元金 + 手数料
      const sumCreditFeePrice = installmentPrincipal + json["credit_fee"];
      this.sumCreditFeePricePlaceholderTarget.textContent =
        sumCreditFeePrice.toLocaleString();

      // 支払総額
      // 分割払金合計 + 頭金合計
      this.sumPaymentPlaceholderTargets.forEach(
        (target) =>
          (target.textContent = (
            this.sumInitialPayment + sumCreditFeePrice
          ).toLocaleString()),
      );

      // ボーナス支払額
      if (
        this.bonusPaymentTarget.checked &&
        this.#isBonusStartDateBeforeEndDate()
      ) {
        this.bonusPricePlaceholderTarget.textContent = Number(
          this.bonusPaymentPriceTarget.value,
        ).toLocaleString();
      } else {
        this.bonusPricePlaceholderTarget.textContent = "0";
      }

      this.loanResultTarget.classList.remove("d-none");

      this.confirmButtonTarget.disabled = false;
    });
  }

  /**
   * 支払期間の年月を表示する
   */
  #setPaymentEndYearMonth() {
    if (this.hasChangeInPaymentStartDateTarget) {
      if (!this.changeInPaymentStartDateTarget.checked) {
        const startDate = format(
          new Date(this.savedPaymentStartDateValue),
          "yyyy-MM-dd",
        );
        this.paymentStartDateTarget.value = startDate;
      }
    }

    const numberOfPayments = Number(this.numberOfPaymentsTarget.value);
    const start = parse(
      this.paymentStartDateTarget.value,
      "yyyy-MM-dd",
      new Date(),
    );

    // 当月も支払い1回分になるので、-1した加算になる
    const end = add(start, { months: numberOfPayments - 1 });

    this.paymentPeriodTarget.textContent =
      format(start, "yyyy年MM月") + " 〜 " + format(end, "yyyy年MM月");
  }

  /**
   * ボーナス初回支払を取得する
   * @param {String} month
   * @returns {Date}
   */
  #getInitialBonusPaymentMonth(month) {
    const start = parse(
      this.paymentStartDateTarget.value,
      "yyyy-MM-dd",
      new Date(),
    );
    const monthValue = this.#getBonusPaymentMonthValue(month);
    const bonusPayment = new Date(
      start.getFullYear(),
      monthValue - 1,
      start.getDate(),
    );

    if (start > bonusPayment) {
      return add(bonusPayment, { years: 1 });
    } else {
      return bonusPayment;
    }
  }

  /**
   * ボーナス月の値を取得する
   * @param {String} bonusPaymentMonth
   * @returns {Number}
   */
  #getBonusPaymentMonthValue(bonusPaymentMonth) {
    switch (bonusPaymentMonth) {
      case "january":
        return 1;
      case "february":
        return 2;
      case "march":
        return 3;
      case "april":
        return 4;
      case "may":
        return 5;
      case "june":
        return 6;
      case "july":
        return 7;
      case "august":
        return 8;
      case "september":
        return 9;
      case "october":
        return 10;
      case "november":
        return 11;
      case "december":
        return 12;
    }

    return 0;
  }

  /**
   * ボーナスの支払月をセットする
   */
  #setBonusPaymentMonth() {
    // ボーナス支払が設定されていない場合
    if (!this.bonusPaymentTarget.checked) {
      this.bonusPaymentStartDatePlaceholderTarget.textContent = "---";
      this.bonusPaymentStartDatePlaceholderTarget.classList.add("text-muted");
      this.bonusPaymentMonthPlaceholderTarget.textContent = "7月と1月";
      return;
    }

    // それぞれの支払月を Date で取得する
    const month1 = this.#getInitialBonusPaymentMonth(
      this.bonusPayment1MonthTarget.value,
    );
    const month2 = this.#getInitialBonusPaymentMonth(
      this.bonusPayment2MonthTarget.value,
    );

    const months = [month1, month2].sort(compareAsc);

    // 最初のボーナス月をスキップするかどうか
    if (this.#isBonusStartDateBeforeEndDate()) {
      if (this.skipFirstBonusMonthTarget.checked) {
        this.bonusPaymentStartDatePlaceholderTarget.textContent = format(
          months[1],
          "yyyy年MM月",
        );
      } else {
        this.bonusPaymentStartDatePlaceholderTarget.textContent = format(
          months[0],
          "yyyy年MM月",
        );
      }

      this.bonusPaymentStartDatePlaceholderTarget.classList.remove(
        "text-muted",
      );
    } else {
      this.bonusPaymentStartDatePlaceholderTarget.textContent = "---";
      this.bonusPaymentStartDatePlaceholderTarget.classList.add("text-muted");
    }

    // 結果に表示する支払月
    this.bonusPaymentMonthPlaceholderTarget.textContent =
      format(months[0], "M月") + "と" + format(months[1], "M月");

    // 最初のボーナス月をスキップするチェックに表示するラベル
    this.skipFirstBonusMonthLabelTarget.textContent = format(
      months[1],
      "yyyy年M月",
    );
  }

  /**
   * ボーナス支払日が支払終了日より前になるかチェック
   * @returns {boolean}
   */
  #isBonusStartDateBeforeEndDate() {
    const month1 = this.#getInitialBonusPaymentMonth(
      this.bonusPayment1MonthTarget.value,
    );
    const month2 = this.#getInitialBonusPaymentMonth(
      this.bonusPayment2MonthTarget.value,
    );
    const numberOfPayments = Number(this.numberOfPaymentsTarget.value);
    const start = parse(
      this.paymentStartDateTarget.value,
      "yyyy-MM-dd",
      new Date(),
    );
    const end = add(start, { months: numberOfPayments - 1 });

    return month1 < end || month2 < end;
  }

  /**
   * シミュレーション結果をAPIで問い合わせる
   * @param {String} params
   * @returns {Promise<any>}
   */
  async #getLoanSimulatorResult(params) {
    const url = this.element.dataset.loanSimulatorUrl + "?" + params;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    });

    return response.json();
  }
}
