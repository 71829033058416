import { Controller } from "@hotwired/stimulus";

/**
 * mypage-listing-car-prices-edit
 */
export default class extends Controller {
  static targets = [
    "price",
    "systemUseFee",
    "transferAmount",
    "releaseOwnership",
    "releaseOwnershipFee",
  ];

  connect() {
    this.updateSystemUseFee();
  }

  validateInputValue(e) {
    const inputedPrice =
      (e.target.value >= 0 && parseInt(e.target.value, 10)) || 0;
    e.target.value = inputedPrice;
  }

  updateSystemUseFee() {
    const inputedPrice = parseInt(this.priceTarget.value, 10) || 0;
    const price = inputedPrice * 10000;
    let systemUseFee = 0;
    let transferAmount = 0;

    // 初期値は事務局で設定した所有権解除費用
    // 事務局で確定していない場合は、後続の処理で可変する
    let releaseOwnershipFee = parseInt(
      this.element.dataset.commitReleaseOwnershipFee,
      10,
    );

    // 所有権解除費用の有無を算出する
    if (this.releaseOwnershipTargets.length > 0) {
      const releaseOwnership = this.releaseOwnershipTargets.find(
        (x) => x.checked,
      )?.value;
      if (releaseOwnership != "unnecessary") {
        releaseOwnershipFee = parseInt(
          this.releaseOwnershipFeeTarget.dataset.price,
          10,
        );
      }
    }

    // 出品最低価格は 7 万円
    if (inputedPrice >= 7) {
      systemUseFee = this.#getSystemUseFee(price);
      transferAmount = price - systemUseFee - releaseOwnershipFee;
    }

    this.systemUseFeeTarget.textContent = systemUseFee.toLocaleString();
    this.transferAmountTarget.textContent = transferAmount.toLocaleString();
    this.releaseOwnershipFeeTarget.textContent =
      releaseOwnershipFee.toLocaleString();
  }

  // ExhibitedCar#seller_system_use_fee と同じ計算式
  #getSystemUseFee(price) {
    return price < 1000000 ? 50000 : price * 0.05;
  }
}
