import { AutocompleteSettings, AutocompleteItem } from "autocompleter";

export interface AutocompleteDataRow extends AutocompleteItem {
  value: string;
  label: string;
  keyword: string;
}

/**
 *
 * @param select An element
 * @returns AutocompleteSettings<AutocompleteDataRow>
 */
export function autocompleteSelectboxConfig(
  select: HTMLSelectElement,
): AutocompleteSettings<AutocompleteDataRow> {
  const vals = Array.from(select.querySelectorAll("option"))
    .filter((el) => el.textContent)
    .map((el): AutocompleteDataRow => {
      return {
        label: el.textContent || "",
        value: el.value,
        keyword: el.value.toLowerCase() + " " + el.textContent?.toLowerCase(),
      };
    });

  const margin = 0 - select.getBoundingClientRect()["height"];
  const input = document.createElement("input");
  input.type = "text";
  input.className = select.className;
  input.style.marginTop = `${margin}px`;
  input.style.opacity = "0";

  if (select.nextSibling) {
    select.parentNode?.insertBefore(input, select.nextSibling);
  } else {
    select.parentNode?.appendChild(input);
  }
  select.style.pointerEvents = "none";
  select.tabIndex = -1;

  select.addEventListener("focus", () => {
    input.focus();
  });
  input.addEventListener("focus", () => {
    input.style.removeProperty("opacity");
    select.style.visibility = "hidden";
  });
  input.addEventListener("blur", () => {
    input.style.opacity = "0";
    select.style.removeProperty("visibility");

    if (input.value != "") {
      input.placeholder = input.value;
      input.value = "";
    }
  });

  const onChange = (
    text: string,
    update: (items: AutocompleteDataRow[]) => void,
  ): void => {
    text = text.toLowerCase();
    const match = vals.find((x) => x.value === text);
    const suggestions = vals.filter(
      (x) => x.keyword.indexOf(text) !== -1 && x != match,
    );
    if (match) suggestions.unshift(match);
    update(suggestions);
  };
  const onSelect = (
    item: AutocompleteDataRow,
    input: HTMLInputElement,
  ): void => {
    if (select.value != item.value) {
      select.value = item.value;
      const ev = new Event("change");
      select.dispatchEvent(ev);
    }
    input.value = item.label;
    input.select();
  };

  return {
    input: input,
    minLength: 0,
    showOnFocus: true,
    preventSubmit: true,
    disableAutoSelect: false,
    fetch: onChange,
    onSelect: onSelect,
  };
}
