/* eslint-disable @typescript-eslint/no-explicit-any */
import { NoVideoInputDevicesError } from "./errors";

function defaultDeviceIdChooser(
  filteredDevices: any,
  videoDevices: any,
  facingMode: any,
): any {
  if (filteredDevices.length > 0) {
    return filteredDevices[0].deviceId;
  }
  if (videoDevices.length == 1 || facingMode == "user") {
    return videoDevices[0].deviceId;
  }
  return videoDevices[1].deviceId;
}

const getFacingModePattern = (facingMode: any): RegExp =>
  facingMode == "environment" ? /rear|back|environment/gi : /front|user|face/gi;

export function getDeviceId(
  facingMode: any,
  chooseDeviceId = defaultDeviceIdChooser,
): Promise<any> {
  // Get manual deviceId from available devices.
  return new Promise((resolve, reject) => {
    let enumerateDevices: any;
    try {
      enumerateDevices = navigator.mediaDevices.enumerateDevices();
    } catch (err) {
      console.error(err);
      reject(new NoVideoInputDevicesError());
    }
    enumerateDevices.then((devices: any) => {
      // Filter out non-videoinputs
      const videoDevices = devices.filter(
        (device: any) => device.kind == "videoinput",
      );

      if (videoDevices.length < 1) {
        reject(new NoVideoInputDevicesError());
        return;
      }

      const pattern = getFacingModePattern(facingMode);

      // Filter out video devices without the pattern
      const filteredDevices = videoDevices.filter(({ label }: any) =>
        pattern.test(label),
      );

      resolve(chooseDeviceId(filteredDevices, videoDevices, facingMode));
    });
  });
}
