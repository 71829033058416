import { Controller } from "@hotwired/stimulus";

/**
 *
 * data-controller="exhibited-car-exterior-scratch"
 *
 * 外装状態の select
 *   data: { action: "exhibited-car-exterior-scratch#changed", exhibited_car_exterior_scratch_target: "select" }
 * キズの位置
 *   data-exhibited-car-exterior-scratch-target="container"
 */
export default class extends Controller {
  static targets = ["select", "container", "positions"];

  connect() {
    this.#setPositionFormActive(this.#hasScratches(this.selectTarget), 0);
  }

  changed(ev) {
    const scratch = this.#hasScratches(ev.currentTarget);
    this.#setPositionFormActive(scratch);
  }

  /**
   * キズありの選択肢か
   * @param {HTMLSelectElement} el
   * @return {boolean}
   */
  #hasScratches(el) {
    // none, almost_no_scratches 以外が選択されている。
    return ["few_scratches", "noticeable_scratch", "fatal_scratch"].includes(
      el.value,
    );
  }

  /**
   * キズの箇所選択フォーム
   * @param {bool} active
   * @param {int} duration animation duration
   */
  #setPositionFormActive(active, duration = 250) {
    if (active) {
      $(this.containerTarget).slideDown(duration);
    } else {
      $(this.containerTarget).slideUp(duration);
    }
    this.positionsTargets.forEach((el) => {
      el.disabled = !active;
    });
  }
}
