import { Controller } from "@hotwired/stimulus";
import consumer from "../../channels/consumer";

/**
 * トークの購読状態に紐づくイベントを実行できるようにする
 */
export default class extends Controller {
  static values = { id: Number, redirectTo: String };
  static targets = ["subscriberNames"];

  connect() {
    this.channel = consumer.subscriptions.create(
      {
        channel: "TalkSubscriptionChannel",
        id: this.idValue,
      },
      {
        received: this.#received,
      },
    );
  }

  disconnect() {
    if (this.channel) {
      consumer.subscriptions.remove(this.channel);
    }
  }

  #received = (data) => {
    if (Object.hasOwn(data, "is_redirect")) {
      if (data.is_redirect) {
        // 指定のリダイレクト先に遷移する
        window.location.href = this.redirectToValue;
      }
    }

    if (Object.hasOwn(data, "subscriber_names")) {
      if (this.hasSubscriberNamesTarget) {
        this.subscriberNamesTarget.textContent = data.subscriber_names;
      }
    }
  };
}
