import { Controller } from "@hotwired/stimulus";

/**
 * 刺した要素の change イベントで属してるフォームを submit (w/ turbo) する
 * select, radio などで使えるはず
 * value が空の場合にはパラメーターを送信しないように手前で無効化してる
 *
 * ```erb
 * <%= f.select :ident, {}, {}, { data: { controller: "change-and-submit" }} %>
 * ```
 */
export default class extends Controller {
  connect() {
    this.form = this.element.form;
    if (!this.form) {
      console.error("⛔️ form 外の要素では使用できません");
      return;
    }

    this.element.addEventListener("change", this.#onChange);
    document.addEventListener("turbo:before-cache", this.#beforeCache);
    this.currentValue = this.element.value;
  }

  disconnect() {
    this.element.removeEventListener("change", this.#onChange);
    document.removeEventListener("turbo:before-cache", this.#beforeCache);
  }

  #onChange = () => {
    // value が空なら disabled にしてパラメーターは送信しない
    this.element.disabled = this.element.value == "";
    // submit() は turbo にならない
    this.form.requestSubmit();
    // 送信したらすぐに戻す
    this.element.disabled = false;
  };

  // turbo は画面遷移の直前にキャッシュするので
  // 画面描画(状態a)→プルダウン変更(b)→キャッシュしてsubmit→画面遷移(状態b)→戻る(状態a & プルダウンb)
  // となってしまうので、キャッシュ直前に値を元に戻してる（なんだこれ？）
  #beforeCache = () => {
    // フォーム更新前の状態に戻す
    this.element.value = this.currentValue;
  };
}
