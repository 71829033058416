import { Controller } from "@hotwired/stimulus";
import { getFileSizeForHuman } from "../../shared/getFileSizeForHuman";
import { readFileAsDataURL } from "../../shared/readFileAsDataURL";

export default class extends Controller {
  static targets = [
    "uploadButton",
    "uploadImage",
    "spinner",
    "textarea",
    "messageSendButton",
    "inputFile",
    "confirmModal",
    "fileListTemplate",
    "fileList",
    "fileItem",
    "fileImage",
    "fileName",
    "fileSize",
    "form",
  ];

  /**
   * ファイルを選択する
   */
  selectFiles() {
    // ファイル選択を初期化する
    this.inputFileTarget.value = "";
    this.inputFileTarget.disabled = false;

    this.inputFileTarget.click();
  }

  /**
   * ファイル送信確認用のモーダルを表示
   */
  showConfirmModal() {
    // 選択されたファイルが何も無い場合は終了
    if (this.inputFileTarget.files.length == 0) {
      return;
    }

    // 選択したファイルでプレビューを作成する
    this.#createPreview();

    // 確認用のモーダルを表示
    window.BootstrapWrapper.showModal(this.confirmModalTarget);
  }

  /**
   * ファイルを送信する
   */
  sendFiles() {
    // ファイルが指定されていない場合は中断
    if (this.inputFileTarget.files.length == 0) {
      return;
    }

    // ファイル選択ボタンを無効化して送信中にする
    this.uploadButtonTarget.disabled = true;
    this.uploadImageTarget.classList.add("d-none");
    this.spinnerTarget.classList.add("spinner-border");

    // テキストエリアとメッセージ送信ボタンを無効化する
    this.textareaTarget.disabled = true;
    this.messageSendButtonTarget.disabled = true;

    const formData = new FormData(this.formTarget);

    fetch(this.formTarget.action, {
      method: "POST",
      body: formData,
    }).then((response) => {
      if (!response.ok) {
        console.error("ファイル送信エラー (" + response.status + ")");

        // 422エラーの場合はリロードする
        if (response.status == 422) {
          location.reload();
        }
      }

      // 無効化していたものを戻す
      this.messageSendButtonTarget.disabled = false;
      this.textareaTarget.disabled = false;
      this.spinnerTarget.classList.remove("spinner-border");
      this.uploadImageTarget.classList.remove("d-none");
      this.uploadButtonTarget.disabled = false;
    });
  }

  /**
   * プレビューを作成する
   */
  #createPreview() {
    // プレビューを初期化する
    this.fileItemTargets.forEach((element) => {
      element.remove();
    });

    // 選択したファイルでプレビュー用の要素を作る
    const files = this.inputFileTarget.files;
    for (let i = 0; i < files.length; i++) {
      const previewItem = document.importNode(
        this.fileListTemplateTarget.content,
        true,
      );
      this.fileListTarget.appendChild(previewItem);
    }

    // ファイルプレビューとファイル名を更新する
    for (let i = 0; i < this.fileItemTargets.length; i++) {
      const file = files[i];

      // 画像の場合はプレビューを更新
      // 更新されない場合はPDFアイコンが出る
      if (file.type.includes("image/")) {
        this.#setPreviewImage(file, this.fileImageTargets[i]);
      }

      // ファイル名
      this.fileNameTargets[i].textContent = file.name;

      // ファイルサイズ
      this.fileSizeTargets[i].textContent = getFileSizeForHuman(file.size);
    }
  }

  async #setPreviewImage(file, target) {
    const url = await readFileAsDataURL(file);
    target.src = url;
  }
}
