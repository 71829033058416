import { determineQRIndex } from "./QRReader/determineClassification";
import { parseKeiQR2, parseKeiQR3 } from "./QRReader/parseKeiQRResults";
import {
  parseHutsuuQR2,
  parseHutsuuQR3,
} from "./QRReader/parseHutsuuQRResults";
import { CarInfo, CarSpec, CarClass, QRIndex } from "./QRReader/types";
import { dispatchQrReaderErrorEvent } from "./events";

class ShakenInfo {
  carClass: CarClass | null = null;
  qrStrings: { [section: number]: { [index: number]: string } } = {};

  append(string: string, qrIndex: QRIndex): void {
    if (this.carClass && this.carClass !== qrIndex.carClass) {
      console.warn("車検証の種類が違います", {
        current: this.carClass,
        next: qrIndex.carClass,
      });
      dispatchQrReaderErrorEvent({ message: "車検証の種類が違います。" });
      return;
    }
    this.carClass = qrIndex.carClass;

    // 普通も軽も 2, 3 だけを使う。
    if (![2, 3].includes(qrIndex.section)) {
      return;
    }

    if (!this.qrStrings[qrIndex.section]) {
      this.qrStrings[qrIndex.section] = {};
    }
    if (!this.qrStrings[qrIndex.section][qrIndex.index]) {
      this.qrStrings[qrIndex.section][qrIndex.index] = string;
    }
  }

  getCarInfo(): CarInfo | null {
    if (this.carClass === "kei" && this.qrStrings[2] && this.qrStrings[2][1]) {
      return parseKeiQR2(this.qrStrings[2][1]);
    }
    if (
      this.carClass === "hutsuu" &&
      this.qrStrings[2] &&
      this.qrStrings[2][1] &&
      this.qrStrings[2][2]
    ) {
      return parseHutsuuQR2(this.qrStrings[2][1] + this.qrStrings[2][2]);
    }
    return null;
  }

  getCarSpec(): CarSpec | null {
    if (this.carClass === "kei" && this.qrStrings[3] && this.qrStrings[3][1]) {
      return parseKeiQR3(this.qrStrings[3][1]);
    }
    if (
      this.carClass === "hutsuu" &&
      this.qrStrings[3] &&
      this.qrStrings[3][1] &&
      this.qrStrings[3][2] &&
      this.qrStrings[3][3]
    ) {
      return parseHutsuuQR3(
        this.qrStrings[3][1] + this.qrStrings[3][2] + this.qrStrings[3][3],
      );
    }
    return null;
  }

  reset(): void {
    this.carClass = null;
    this.qrStrings = {};
  }
}

export class ShakenshouReader {
  shakenInfo: ShakenInfo = new ShakenInfo();

  yonda(string: string): void {
    const qrIndex = determineQRIndex(string);
    if (qrIndex) {
      this.shakenInfo.append(string, qrIndex);
    } else {
      // 判定できない
    }
  }
}
