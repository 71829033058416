import React, { useEffect, useState } from "react";
import { Client, Conversation, Message } from "@twilio/conversations";
import MessageItem, { MessageUser } from "./MessageItem";
import {
  getUnreadMessagesCountByConversation,
  sort,
} from "components/chatFunctions";
import Loading from "components/Loading";
import { ErrorBoundary } from "components/ErrorBoundary";
import { useAsyncCatchError } from "./useAsyncCatchError";
import { getTwilioConnection } from "shared/TwilioConnection";

interface Props {
  conversationSidAndUser: { [sid: string]: MessageUser };
  accessToken: string;
  storeStaffDomainPath: string;
  identity: string;
}

type ConversationAndLastMessage = {
  conversation: Conversation;
  message: Message;
  hasUnread: boolean;
};

type ConversationData = {
  conversation: Conversation;
  messageUser: MessageUser;
  hasUnread: boolean;
};

/**
 * メッセージ画面でのチャット一覧
 * 各チャットには最後のメッセージと必要なら未読アイコンを表示
 */
const ChatMessageList: React.FC<Props> = (props: Props) => {
  const {
    conversationSidAndUser,
    accessToken,
    storeStaffDomainPath,
    identity,
  } = props;
  const [conversations, setConversations] = useState<
    ConversationAndLastMessage[]
  >([]);
  const [loading, setLoading] = useState<boolean>(true);
  const runAsync = useAsyncCatchError();

  /**
   * TODO
   * twilio sdkの初期化〜conversation取得まで、店舗スタッフホーム画面(ChatLastMessageList)と同様
   * 未読メッセージの有無によって最終メッセージを取得するかどうかが異なる
   * 共通化を考える
   */
  useEffect(() => {
    runAsync(async () => {
      const client: Client = await getTwilioConnection()
        .setAccessToken(accessToken)
        .getClient();

      // サーバーで管理しているconversationと未読をtwilioから参照
      const getConversation: (
        client: Client,
        sid: string,
        messageUser: MessageUser,
      ) => Promise<ConversationData> = async (
        client: Client,
        sid: string,
        messageUser: MessageUser,
      ) => {
        const conversation = await client.getConversationBySid(sid);
        const count = await getUnreadMessagesCountByConversation(
          conversation,
          identity,
        );
        return {
          conversation,
          messageUser,
          hasUnread: Boolean(count > 0),
        };
      };

      const result = await Promise.all(
        Object.entries(conversationSidAndUser).map(([sid, messageUser]) =>
          getConversation(client, sid, messageUser),
        ),
      );
      const allConversations = await Promise.all(
        result.map((x) => {
          if (x.conversation.lastMessage?.index !== undefined) {
            return x.conversation
              .getMessages(1, x.conversation.lastMessage.index)
              .then((paginator) => {
                const messages = paginator.items;
                return {
                  conversation: x.conversation,
                  message: messages.length > 0 ? messages[0] : null,
                  hasUnread: x.hasUnread,
                };
              });
          } else {
            return Promise.resolve({
              conversation: x.conversation,
              message: null,
              hasUnread: x.hasUnread,
            });
          }
        }),
      );

      setConversations(
        allConversations.filter(
          (c) => c.message,
        ) as ConversationAndLastMessage[],
      );
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    // 更新後のconversationsを対象に未読更新のメソッドを生成
    const updateUnreadState = (msg: Message): void => {
      const newConversations = conversations.map((x) => {
        if (
          x.conversation.sid === msg.conversation.sid &&
          x.message.sid !== msg.sid
        ) {
          x.message = msg;
          x.hasUnread = msg.author !== identity;
        }

        return x;
      });

      setConversations(newConversations);
    };

    // ConversationをListenして新しいメッセージ受信時に反映する
    conversations.forEach((conversation) => {
      conversation.conversation.addListener("messageAdded", updateUnreadState);
    });

    return () => {
      conversations.forEach((conversation) => {
        conversation.conversation.removeListener(
          "messageAdded",
          updateUnreadState,
        );
      });
    };
  }, [conversations]);

  return (
    <Loading loading={loading}>
      {conversations.length === 0 ? (
        <>
          <div className="missing">
            <i className="fa-5x far fa-comments"></i>
            <div className="h1">トークルームがありません</div>
            <p className="message">
              担当しているお客様がモビリコに登録するとメッセージを送受信できるようになります。
            </p>
          </div>
        </>
      ) : (
        <>
          <header className="main-header container">
            <div className="d-flex align-items-end justify-content-between font-sm line-height-sm">
              {conversations.filter((c) => c.hasUnread).length === 0 ||
              accessToken === "" ? (
                <span className="font-sm">未読のメッセージはありません。</span>
              ) : (
                <span>
                  <strong>
                    <span className="font-base">
                      {conversations.filter((c) => c.hasUnread).length}
                    </span>
                    名
                  </strong>
                  のお客様から新規トークがあります。
                </span>
              )}
            </div>
          </header>
          <div className="wrapper bg-white broadcast-button-space">
            <div className="main-body">
              <div className="container">
                <ul className="list-group">
                  {sort(conversations).map((conversation) => (
                    <MessageItem
                      message={conversation.message}
                      user={
                        conversationSidAndUser[conversation.conversation.sid]
                      }
                      unread={conversation.hasUnread}
                      key={conversation.conversation.sid}
                      storeStaffDomainPath={storeStaffDomainPath}
                      type={
                        conversation.message.author === identity
                          ? "give"
                          : "receive"
                      }
                    />
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </>
      )}
    </Loading>
  );
};

const ChatMessageListApp: React.FC<Props> = (props: Props) => {
  return (
    <ErrorBoundary>
      <ChatMessageList {...props} />
    </ErrorBoundary>
  );
};

export default ChatMessageListApp;
