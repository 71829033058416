/* eslint-disable @typescript-eslint/no-explicit-any */
import encoding from "encoding-japanese";
import { CustomError } from "ts-custom-error";
type HTMLVisualMediaElement = HTMLVideoElement | HTMLImageElement;

/**
 * Custom Error class of type Exception.
 */
class Exception extends CustomError {}
class ChecksumException extends Exception {}
class FormatException extends Exception {}
class NotFoundException extends Exception {}

export type DecodeResult = {
  string: string;
  count: number;
  index: number;
};

// https://github.com/zxing-js/library/blob/be06578976773f901e25b6711d321b72445da5ed/src/browser/BrowserCodeReader.ts
// コピペしてから不要な部分を消したもの
export class BrowserCodeReader {
  zxing: any;
  decodePtr: any;
  private imageElement?: HTMLImageElement;
  private imageLoadedListener!: EventListener;
  private videoElement?: HTMLVideoElement;
  private stream: MediaStream | undefined;
  private videoCanPlayListener!: EventListener;
  private videoEndedListener!: EventListener;
  private videoPlayingEventListener!: EventListener;
  private _stopContinuousDecode?: boolean = false;
  private _stopAsyncDecode = false;
  private requestAnimationFrameID: number | null = null;

  public constructor(
    private callbackFn: (result: DecodeResult | null, error: any) => void,
    protected timeBetweenScansMillis: number = 500,
  ) {
    this.initalizeZXing = this.initalizeZXing.bind(this);
    this.initalizeZXing();
  }

  initalizeZXing(): void {
    if (!this.zxing && window.ZXing) {
      this.zxing = window.ZXing();
      this.decodePtr = this.zxing.Runtime.addFunction(
        (ptr: number, len: number, index: number, count: number) => {
          const result: any = new Uint8Array(
            this.zxing.HEAPU8.buffer,
            ptr,
            len,
          );
          const string = String.fromCharCode.apply(
            null,
            encoding.convert(result, "UNICODE", "SJIS"),
          );
          this.callbackFn({ string, count, index }, null);
        },
      );
    } else {
      setTimeout(this.initalizeZXing, 10);
    }
  }

  async decodeFromVideoDevice(
    deviceId: string | null,
    videoSource: string | HTMLVideoElement,
  ): Promise<void> {
    let videoConstraints: MediaTrackConstraints;

    if (!deviceId) {
      videoConstraints = { facingMode: "environment" };
    } else {
      videoConstraints = { deviceId: { exact: deviceId } };
    }

    const constraints: MediaStreamConstraints = {
      audio: false,
      video: videoConstraints,
    };

    return await this.decodeFromConstraints(constraints, videoSource);
  }

  async decodeFromConstraints(
    constraints: MediaStreamConstraints,
    videoSource: string | HTMLVideoElement,
  ): Promise<void> {
    const stream = await navigator.mediaDevices.getUserMedia(constraints);

    return await this.decodeFromStream(stream, videoSource);
  }

  async decodeFromStream(
    stream: MediaStream,
    videoSource: string | HTMLVideoElement,
  ): Promise<void> {
    this.reset();

    const video = await this.attachStreamToVideo(stream, videoSource);

    return this.decodeContinuously(video);
  }

  async attachStreamToVideo(
    stream: MediaStream,
    videoSource: string | HTMLVideoElement,
  ): Promise<HTMLVideoElement> {
    const videoElement = this.prepareVideoElement(videoSource);

    this.addVideoSource(videoElement, stream);

    this.videoElement = videoElement;
    this.stream = stream;

    await this.playVideoOnLoadAsync(videoElement);

    return videoElement;
  }

  playVideoOnLoadAsync(videoElement: HTMLVideoElement): Promise<void> {
    return new Promise((resolve) =>
      this.playVideoOnLoad(videoElement, () => resolve()),
    );
  }
  playVideoOnLoad(element: HTMLVideoElement, callbackFn: EventListener): void {
    this.videoEndedListener = () => this.stopStreams();
    this.videoCanPlayListener = () => this.tryPlayVideo(element);

    element.addEventListener("ended", this.videoEndedListener);
    element.addEventListener("canplay", this.videoCanPlayListener);
    element.addEventListener("playing", callbackFn);

    // if canplay was already fired, we won't know when to play, so just give it a try
    this.tryPlayVideo(element);
  }

  isVideoPlaying(video: HTMLVideoElement): boolean {
    return (
      video.currentTime > 0 &&
      !video.paused &&
      !video.ended &&
      video.readyState > 2
    );
  }

  /**
   * Just tries to play the video and logs any errors.
   * The play call is only made is the video is not already playing.
   */
  async tryPlayVideo(videoElement: HTMLVideoElement): Promise<void> {
    if (this.isVideoPlaying(videoElement)) {
      console.warn("Trying to play video that is already playing.");
      return;
    }

    try {
      await videoElement.play();
    } catch {
      console.warn("It was not possible to play the video.");
    }
  }

  prepareVideoElement(
    videoSource: HTMLVideoElement | string,
  ): HTMLVideoElement {
    let videoElement: HTMLVideoElement;
    if (typeof videoSource === "string") {
      videoElement = document.getElementById(videoSource) as HTMLVideoElement;
    } else if (videoSource instanceof HTMLVideoElement) {
      videoElement = videoSource;
    } else {
      videoElement = document.createElement("video");
      videoElement.width = 200;
      videoElement.height = 200;
    }

    // Needed for iOS 11
    videoElement.setAttribute("autoplay", "true");
    videoElement.setAttribute("muted", "true");
    videoElement.setAttribute("playsinline", "true");

    return videoElement;
  }

  addVideoSource(videoElement: HTMLVideoElement, stream: MediaStream): void {
    // Older browsers may not have `srcObject`
    try {
      // @note Throws Exception if interrupted by a new loaded request
      videoElement.srcObject = stream;
    } catch (err) {
      // @note Avoid using this in new browsers, as it is going away.
      // @ts-expect-error: TS2345: Argument of type 'MediaStream' is not assignable to parameter of type 'Blob | MediaSource'.
      // https://github.com/zxing-js/library/blob/99a8e0c65de7bf97565a5dd46299d858c10dd69a/src/browser/BrowserCodeReader.ts#L1191-L1193 では @ts-ignore で回避している。
      videoElement.src = URL.createObjectURL(stream);
      console.error(err);
    }
  }

  decodeContinuously(element: HTMLVideoElement): void {
    this._stopContinuousDecode = false;

    const loop = (): void => {
      if (this._stopContinuousDecode) {
        this._stopContinuousDecode = undefined;
        return;
      }

      try {
        this.decode(element);
        this.requestAnimationFrameID = requestAnimationFrame(loop);
      } catch (e) {
        const isChecksumOrFormatError =
          e instanceof ChecksumException || e instanceof FormatException;
        const isNotFound = e instanceof NotFoundException;

        if (isChecksumOrFormatError || isNotFound) {
          // trying again
          this.requestAnimationFrameID = requestAnimationFrame(loop);
        } else {
          this.callbackFn(null, e);
        }
      }
    };

    loop();
  }

  decode(element: HTMLVisualMediaElement): void {
    const ctx = this.getCaptureCanvasContext(element);

    ctx.drawImage(element, 0, 0);

    const canvas = this.getCaptureCanvas(element);

    if (canvas.width <= 0 || canvas.height <= 0) {
      return;
    }

    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    const idd = imageData.data;
    const image = this.zxing._resize(canvas.width, canvas.height);

    // グレースケール化
    for (let i = 0, j = 0; i < idd.length; i += 4, j++) {
      const [r, g, b] = [idd[i], idd[i + 1], idd[i + 2]];
      this.zxing.HEAPU8[image + j] = Math.trunc((r + g + b) / 3);
    }

    const err = this.zxing._decode_qr_multi(this.decodePtr);
    if (err === -2) {
      throw new NotFoundException();
    }
  }

  getCaptureCanvasContext(
    mediaElement?: HTMLVisualMediaElement,
  ): CanvasRenderingContext2D {
    if (!this.captureCanvasContext) {
      const elem = this.getCaptureCanvas(mediaElement);
      const ctx = elem.getContext("2d", { alpha: false });
      this.captureCanvasContext = ctx!;
    }

    return this.captureCanvasContext!;
  }

  getCaptureCanvas(mediaElement?: HTMLVisualMediaElement): HTMLCanvasElement {
    if (!this.captureCanvas) {
      const elem = this.createCaptureCanvas(mediaElement);
      this.captureCanvas = elem!;
    }

    return this.captureCanvas!;
  }

  public createCaptureCanvas(
    mediaElement?: HTMLVisualMediaElement,
  ): HTMLCanvasElement {
    if (typeof document === "undefined") {
      this._destroyCaptureCanvas();
      throw new Error("document === undefined");
    }

    const canvasElement = document.createElement("canvas");

    let width = 0;
    let height = 0;

    if (typeof mediaElement !== "undefined") {
      if (mediaElement instanceof HTMLVideoElement) {
        width = mediaElement.videoWidth;
        height = mediaElement.videoHeight;
      } else if (mediaElement instanceof HTMLImageElement) {
        width = mediaElement.naturalWidth || mediaElement.width;
        height = mediaElement.naturalHeight || mediaElement.height;
      }
    }

    canvasElement.style.width = width + "px";
    canvasElement.style.height = height + "px";
    canvasElement.width = width;
    canvasElement.height = height;

    return canvasElement;
  }

  protected stopStreams(): void {
    if (this.stream) {
      this.stream.getVideoTracks().forEach((t) => t.stop());
      this.stream = undefined;
    }
    if (this._stopAsyncDecode === false) {
      this.stopAsyncDecode();
    }
    if (this._stopContinuousDecode === false) {
      this.stopContinuousDecode();
    }
    if (this.requestAnimationFrameID) {
      cancelAnimationFrame(this.requestAnimationFrameID);
    }
  }
  stopAsyncDecode(): void {
    this._stopAsyncDecode = true;
  }

  stopContinuousDecode(): void {
    this._stopContinuousDecode = true;
  }

  reset(): void {
    // stops the camera, preview and scan 🔴
    this.stopStreams();
    // clean and forget about HTML elements
    this._destroyVideoElement();
    this._destroyImageElement();
    this._destroyCaptureCanvas();
  }
  private _destroyVideoElement(): void {
    if (!this.videoElement) {
      return;
    }

    // first gives freedon to the element 🕊

    if (typeof this.videoEndedListener !== "undefined") {
      this.videoElement.removeEventListener("ended", this.videoEndedListener);
    }

    if (typeof this.videoPlayingEventListener !== "undefined") {
      this.videoElement.removeEventListener(
        "playing",
        this.videoPlayingEventListener,
      );
    }

    if (typeof this.videoCanPlayListener !== "undefined") {
      this.videoElement.removeEventListener(
        "loadedmetadata",
        this.videoCanPlayListener,
      );
    }

    // then forgets about that element 😢

    this.cleanVideoSource(this.videoElement);

    this.videoElement = undefined;
  }
  private _destroyImageElement(): void {
    if (!this.imageElement) {
      return;
    }

    // first gives freedon to the element 🕊

    if (undefined !== this.imageLoadedListener) {
      this.imageElement.removeEventListener("load", this.imageLoadedListener);
    }

    // then forget about that element 😢

    this.imageElement.src = undefined as any;
    this.imageElement.removeAttribute("src");
    this.imageElement = undefined;
  }
  captureCanvasContext?: CanvasRenderingContext2D;
  captureCanvas?: HTMLCanvasElement;

  /**
   * Cleans canvas references 🖌
   */
  private _destroyCaptureCanvas(): void {
    // then forget about that element 😢

    this.captureCanvasContext = undefined;
    this.captureCanvas = undefined;
  }
  private cleanVideoSource(videoElement: HTMLVideoElement): void {
    try {
      videoElement.srcObject = null;
    } catch (err) {
      videoElement.src = "";
      console.error(err);
    }

    this.videoElement?.removeAttribute("src");
  }
}
