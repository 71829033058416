import loadImage, { LoadImageOptions } from "blueimp-load-image";

export function canvasToBlobAsync(
  canvas: HTMLCanvasElement,
  mimeType: string,
  qualityArgument: number,
): Promise<Blob> {
  return new Promise((resolve, reject) => {
    canvas.toBlob(
      (blob) => (blob ? resolve(blob) : reject()),
      mimeType,
      qualityArgument,
    );
  });
}

type Options = LoadImageOptions & { canvas: true };
export async function loadImageToBlob(
  file: File | Blob | string,
  options: Options,
  mimeType: string,
  qualityArgument: number,
): Promise<Blob> {
  options.canvas = true;
  const image = await loadImage(file, options);
  const canvas = image.image as HTMLCanvasElement;

  const blob = await canvasToBlobAsync(canvas, mimeType, qualityArgument);
  return blob;
}
