import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "survey",
    "input",
    "progressBar",
    "progressGuide",
    "submitButton",
    "nextButton",
    "prevButton",
  ];

  connect() {
    this.currentIndex = 0;
    this.#showSection("next");
  }

  /**
   * 次の質問を表示する
   */
  next() {
    this.currentIndex++;
    this.#showSection("next");
  }

  /**
   * 前の質問を表示する
   */
  prev() {
    this.currentIndex--;
    this.#showSection("prev");
  }

  /**
   * 現在位置の質問を表示する
   * @param {String} direction
   */
  #showSection(direction) {
    for (const [index, survey] of this.surveyTargets.entries()) {
      survey.style.display = index === this.currentIndex ? "block" : "none";
    }

    // スキップ対象か判定
    const currentSurvey = this.surveyTargets[this.currentIndex];
    const question = currentSurvey.dataset.question;
    const formType = currentSurvey.dataset.formType;
    if (this.#isSkip(currentSurvey)) {
      if (direction === "next") {
        this.currentIndex++;
      } else {
        this.currentIndex--;
      }

      // 送信予定の input を無効化しておく
      this.#updateInputDisabled(formType, question, true);
      this.#showSection(direction);
      return;
    }

    this.#updateInputDisabled(formType, question, false);
    this.#updateProgressBar();
    this.#showButton();
  }

  /**
   * スキップ対象の質問か判定する
   * @param {object} currentSurvey
   * @returns {Boolean}
   */
  #isSkip(currentSurvey) {
    const triggerQuestion = currentSurvey.dataset.triggerQuestion;

    if (triggerQuestion) {
      const triggerAnswer = currentSurvey.dataset.triggerAnswer;
      const triggerInput = this.inputTargets.find(
        (input) =>
          input.name === triggerQuestion && input.value === triggerAnswer,
      );

      // `triggerQuestion` として指定された質問の回答が `triggerAnswer` の場合に表示する質問
      // `triggerAnswer` の回答以外だった場合はスキップ対象にする
      // MEMO: 最後の質問は自由記入になってるので最後には配置されないはず…
      if (!triggerInput.checked) {
        return true;
      }
    }

    // スキップ判定では無い場合は有効化
    return false;
  }

  /**
   * プログレスバーを更新する
   */
  #updateProgressBar() {
    const progressPercentage =
      ((this.currentIndex + 1) / this.surveyTargets.length) * 100;
    this.progressBarTarget.style.width = `${progressPercentage}%`;
    this.progressBarTarget.setAttribute("aria-valuenow", progressPercentage);

    this.#updateProgressGuide();
  }

  /**
   * プログレスガイドを更新する
   */
  #updateProgressGuide() {
    this.progressGuideTarget.dataset.currentSection = this.currentIndex + 1;
  }

  /**
   * 現在位置に応じてボタン表示を切り替える
   */
  #showButton() {
    const currentIndex = this.currentIndex + 1;

    // 回答送信ボタンは最後のページのみ表示
    this.submitButtonTarget.style.display =
      currentIndex === this.surveyTargets.length ? "block" : "none";

    // 次へ進むボタンは最後のページ以外で表示
    this.nextButtonTarget.style.display =
      currentIndex === this.surveyTargets.length ? "none" : "block";

    // 前に戻るボタンは最初のページでは無効化
    this.prevButtonTarget.disabled = currentIndex === 1;
  }

  /**
   * input の無効化状態を更新する
   * @param {String} formType
   * @param {String} question
   * @param {Boolean} disabled
   */
  #updateInputDisabled(formType, question, disabled) {
    const filteredInputs = this.inputTargets.filter((input) => {
      if (formType === "check_box_form") {
        return input.name === `${question}[]`;
      }
      return input.name === question;
    });

    for (const input of filteredInputs) {
      input.disabled = disabled;
    }
  }
}
