import { Controller } from "@hotwired/stimulus";

/**
 * 非同期コメント投稿
 * いずれ admin/ujs_remote_controller.js 的なもので置き換えられるはず
 *
 * data-controller="exhibited-car-comment"
 *
 * メッセージ欄. replaceChild するので必ず this.element の子要素
 *   data-exhibited-car-comment-target="partial"
 * 削除ダイアログの本文を表示するエリア
 *   data-exhibited-car-comment-target="modalBody"
 * 削除ボタンリンク
 *   <a data-exhibited-car-comment-target="delete" data-method="delete" data-remote="true" data-dismiss="modal">
 */
export default class extends Controller {
  static targets = ["partial", "modalBody", "delete"];

  connect() {
    this.bind();
  }

  bind() {
    document.body.addEventListener("ajax:success", (ev) => {
      this.onSuccess(ev);
    });
  }

  onSuccess(ev) {
    if (!this.hasPartialTarget) return;
    const node = ev.detail[0].querySelector(
      '[data-exhibited-car-comment-target="partial"]',
    );
    if (!node) return;
    this.element.replaceChild(node, this.partialTarget);
  }

  confirmation(ev) {
    const data = ev.currentTarget.dataset;
    this.setConfirmationModalContent(data.comment, data.url);
  }

  setConfirmationModalContent(comment, url) {
    this.modalBodyTarget.innerText = comment;
    this.deleteTarget.href = url;
  }
}
