import { Controller } from "@hotwired/stimulus";
import consumer from "../../channels/consumer";

/**
 * アプリのフッターメニューのトークバッジの未読数を同期する。
 *
 * data-controller="talk-badge"
 */
export default class extends Controller {
  static values = { isLogin: Boolean };

  connect() {
    // disconnect で後片付けするための処理。
    this.disposers = [];

    // TalkBadgeChannel 経由で取得した未読数。
    this.count = 0;

    if (this.isLoginValue) {
      const channel = consumer.subscriptions.create("TalkBadgeChannel", {
        received: this.#received,
      });
      this.disposers.push(() => consumer.subscriptions.remove(channel));
    }

    // MobilicoApp の準備ができてなかった場合でも、後から送る。
    document.addEventListener("MobilicoAppLoaded", this.#trySendCountToApp);
    this.disposers.push(() =>
      document.removeEventListener(
        "MobilicoAppLoaded",
        this.#trySendCountToApp,
      ),
    );
  }

  disconnect() {
    this.disposers.forEach((disposer) => disposer());
    this.disposers = [];
  }

  #received = (data) => {
    if (Object.hasOwn(data, "talk_badge")) {
      this.count = data.talk_badge;
    }

    // 未読件数を送る。
    this.#trySendCountToApp();
  };

  #trySendCountToApp = () => {
    if (!this.isLoginValue) {
      window.MobilicoApp?.updateUnreadMessagesCount?.(0, { login: false });
      return;
    }

    // 確認しやすいように画面にも出しておく。
    this.element.textContent = this.count;

    // アプリに未読件数を渡す。
    window.MobilicoApp?.updateUnreadMessagesCount?.(this.count, {
      login: true,
    });
  };
}
