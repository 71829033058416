import React, { useEffect, useState } from "react";
import { ErrorBoundary } from "./ErrorBoundary";
import { Client, Message } from "@twilio/conversations";
import Skeleton from "react-loading-skeleton";
import {
  toDateString,
  getUnreadMessagesCountByConversation,
} from "components/chatFunctions";
import { useAsyncCatchError } from "./useAsyncCatchError";
import { getTwilioConnection } from "shared/TwilioConnection";

interface UserTwilioConversation {
  kind: "UserTwilioConversation";
  sid: string | null;
  name: string;
  endpoint: string;
  store: string;
  discarded: boolean;
}

interface UserBroadcastConversation {
  kind: "UserBroadcastConversation";
  name: string;
  endpoint: string;
  store: string;
  text: string;
  updatedAt: number;
  unread: number;
}

type UserConversation = UserTwilioConversation | UserBroadcastConversation;

interface ConversationResult {
  name: string;
  endpoint: string;
  store: string;
  unreadCount: number;
  message?: Message;
  text?: string;
  updatedAt?: Date;
}

interface Props {
  accessToken: string | null;
  userConversations: UserConversation[];
}

const UserConversations: React.FC<Props> = (props: Props) => {
  const { accessToken, userConversations } = props;

  const [unreads, setUnreads] = useState<number | null>(null);
  const [conversations, setConversations] = useState<ConversationResult[]>([]);
  const runAsync = useAsyncCatchError();

  useEffect(() => {
    runAsync(async () => {
      // アクセストークンを発行できない場合は誰とも一度もトークをしたことがない。
      if (accessToken === null) {
        setConversations(
          userConversations.map((value) => {
            // Admin からの送信がある場合はそれを格納する
            if (value.kind == "UserBroadcastConversation") {
              return {
                name: value.name,
                endpoint: value.endpoint,
                store: value.store,
                unreadCount: value.unread,
                message: undefined,
                text: value.text,
                updatedAt: new Date(value.updatedAt),
              };
            }

            return {
              name: value.name,
              endpoint: value.endpoint,
              store: value.store,
              unreadCount: 0,
              message: undefined,
              text: undefined,
              updatedAt: undefined,
            };
          }),
        );
        setUnreads(0);
        return;
      }

      const client: Client = await getTwilioConnection()
        .setAccessToken(accessToken)
        .getClient();

      // Conversations から最後のメッセージと投稿日時を取得する。
      const conversationResults: ConversationResult[] = await Promise.all(
        userConversations.map(async (value) => {
          if (value.kind === "UserBroadcastConversation") {
            return {
              name: value.name,
              endpoint: value.endpoint,
              store: value.store,
              unreadCount: value.unread,
              message: undefined,
              text: value.text,
              updatedAt: new Date(value.updatedAt),
            };
          }
          if (value.sid === null) {
            return {
              name: value.name,
              endpoint: value.endpoint,
              store: value.store,
              unreadCount: 0,
              message: undefined,
              text: undefined,
              updatedAt: undefined,
            };
          }
          const conversation = await client.getConversationBySid(value.sid);
          const unreadCount =
            await getUnreadMessagesCountByConversation(conversation);
          const messages = await conversation.getMessages(
            1,
            conversation.lastMessage?.index,
          );
          const lastMessage =
            messages.items.length > 0 ? messages.items[0] : undefined;

          let staffName = value.name;
          let store = value.store;
          if (value.discarded) {
            staffName = "メンバーがいません";
            store = "";
          }
          return {
            name: staffName,
            endpoint: value.endpoint,
            store: store,
            unreadCount: unreadCount,
            message: lastMessage,
            text: lastMessage?.body,
            updatedAt: lastMessage?.dateCreated,
          };
        }),
      );

      setConversations(conversationResults);

      // 全体の未読件数を取得する
      setUnreads(
        conversationResults.reduce((current, r) => {
          return current + r.unreadCount;
        }, 0),
      );
    });
  }, []);

  useEffect(() => {
    // 更新後のconversationsを対象に未読更新メソッドを生成
    const updateUnreadState = (msg: Message): void => {
      const newConversations = conversations.map((x) => {
        if (
          x.message?.conversation.sid === msg.conversation.sid &&
          x.message?.sid !== msg.sid
        ) {
          x.message = msg;
          x.text = msg.body;
          x.updatedAt = msg.dateCreated;
          x.unreadCount++;
        }

        return x;
      });

      setConversations(newConversations);
    };

    // 新しいメッセージ受信を反映する
    conversations.forEach((x) => {
      x.message?.conversation.addListener("messageAdded", updateUnreadState);
    });

    return () => {
      conversations.forEach((x) => {
        x.message?.conversation.removeListener(
          "messageAdded",
          updateUnreadState,
        );
      });
    };
  }, [conversations]);

  return (
    <>
      {unreads === null ? (
        <>
          <Skeleton
            height="1.2rem"
            width="60%"
            className="mb-3"
            baseColor="#eaeaea"
            highlightColor="#f4f5f6"
            borderRadius="0.2rem"
          ></Skeleton>
          <Skeleton
            height="4.2rem"
            className="mb-3"
            baseColor="#eaeaea"
            highlightColor="#f4f5f6"
            borderRadius="0.375rem"
          ></Skeleton>
        </>
      ) : (
        <p>
          {unreads === 0 ? (
            "未読のメッセージはありません"
          ) : (
            <>
              未読のメッセージが<strong className="h3 px-1">{unreads}</strong>
              件あります。
            </>
          )}
        </p>
      )}

      {updatedAtSort(conversations).map((conversation) => {
        return (
          <div className="card" key={conversation.endpoint}>
            <div className="card-body">
              <a
                href={`/mypage/talkrooms/${conversation.endpoint}`}
                className="text-body"
              >
                <p className="mb-0 ml-auto text-muted text-right">
                  <small>
                    {conversation.updatedAt
                      ? toDateString(conversation.updatedAt)
                      : null}
                  </small>
                </p>
                <div className="d-flex flex-row align-items-center mt-n3">
                  <div>
                    <i
                      style={{ fontSize: "3rem", color: "#c2c5c7" }}
                      className="staff-fill"
                    ></i>
                  </div>
                  <p className="flex-grow-1 mb-0 p-2 font-weight-bold">
                    {conversation.name}
                    <small className="d-block text-muted">
                      {conversation.store}
                    </small>
                  </p>
                  {conversation.unreadCount > 0 ? (
                    <div className="mt-n1">
                      <span className="badge badge-pill badge-danger ml-1">
                        {conversation.unreadCount}
                      </span>
                    </div>
                  ) : null}
                </div>
                <p
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                  className={`mb-0 ${
                    conversation.unreadCount == 0 ? "text-muted" : ""
                  }`}
                >
                  {conversation.text}
                </p>
              </a>
            </div>
          </div>
        );
      })}
    </>
  );
};

const UserConversationsApp: React.FC<Props> = (props: Props) => {
  return (
    <ErrorBoundary>
      <UserConversations {...props} />
    </ErrorBoundary>
  );
};

/**
 * チャット一覧表示でbroadcast_messagesに対応した一覧を最終発言日時の昇順にソート
 */
function updatedAtSort(
  conversations: ConversationResult[],
): Array<ConversationResult> {
  return conversations.sort((a, b) => {
    if (!a.updatedAt && !b.updatedAt) {
      return 0;
    }
    if (!a.updatedAt) {
      return 1;
    }
    if (!b.updatedAt) {
      return -1;
    }

    if (a.updatedAt.getTime() < b.updatedAt.getTime()) {
      return 1;
    } else {
      return -1;
    }
  });
}

export default UserConversationsApp;
