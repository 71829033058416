export default class {
  constructor() {
    this._parameterBag = {
      type: "GET",
      beforeSend: (xhr) => {
        xhr.setRequestHeader(
          "X-CSRF-Token",
          $('meta[name="csrf-token"]').attr("content"),
        );
      },
    };
  }

  delete(url, success) {
    this._parameterBag.type = "DELETE";
    this._parameterBag.url = url;
    this._parameterBag.success = success;

    $.ajax(this._parameterBag);
  }

  create(url, success) {
    this._parameterBag.type = "POST";
    this._parameterBag.url = url;
    this._parameterBag.success = success;

    $.ajax(this._parameterBag);
  }
}
