import { Controller } from "@hotwired/stimulus";
import consumer from "../../channels/consumer";

/**
 * トークの未読数を同期する
 * turboが使えるview内で処理が完結できるならこっちを使う(アプリ上にカウントを入れる必要が無い場合)
 */
export default class extends Controller {
  static values = { type: String };
  static targets = ["count", "talkroomCount", "talkUnread"];

  connect() {
    this.channel = consumer.subscriptions.create(
      { channel: "TurboTalkBadgeChannel", type: this.typeValue },
      {
        received: this.#received,
      },
    );
  }

  disconnect() {
    if (this.channel) {
      consumer.subscriptions.remove(this.channel);
    }
  }

  #received = (data) => {
    // 未読メッセージ数を更新する
    if (Object.hasOwn(data, "unread_count")) {
      for (const target of this.countTargets) {
        target.textContent = data.unread_count;
      }
    }

    // 未読トークルーム数を更新する
    if (Object.hasOwn(data, "unread_talkroom_count")) {
      for (const target of this.talkroomCountTargets) {
        target.textContent = data.unread_talkroom_count;
      }

      // コンテンツ表示非表示を指定している場合は未読ステータスを入れる
      // data-talk-unreads に値を入れる
      for (const target of this.talkUnreadTargets) {
        target.dataset.talkUnreads = data.unread_talkroom_count > 0;
      }
    }
  };
}
