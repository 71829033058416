import {
  Client,
  Conversation,
  ConversationUpdatedEventArgs,
} from "@twilio/conversations";
import { getUnreadMessagesCount } from "components/chatFunctions";

type UnreadMessagesCountCallback = (count: number) => void;

export class TwilioConnection {
  private client?: Promise<Client>;
  private initializedClient?: Client;
  private accessToken?: string;
  private identity?: string | null;
  private visibleConversationSids: string[] = [];
  private unreadMessagesCountCallbacks?: Set<UnreadMessagesCountCallback>;
  private unreadMessagesCount?: number;
  private isUpdatingUnreadMessages = false;

  setAccessToken = (accessToken: string): TwilioConnection => {
    this.accessToken = accessToken;
    return this;
  };

  setIdentity = (identity: string | null): TwilioConnection => {
    this.identity = identity;
    return this;
  };

  getClient = async (): Promise<Client> => {
    if (!this.client) {
      this.client = this.createClient();
    }

    return this.client;
  };

  // conversationUpdated に登録する。
  private onConversationUpdated = async ({
    conversation,
  }: ConversationUpdatedEventArgs): Promise<void> => {
    // 同時に何回も呼ばれることがある。
    // 集計中だったら無視する。
    if (this.isUpdatingUnreadMessages) {
      return;
    }

    const client = this.initializedClient;
    if (client === undefined) {
      // イベントが飛んでくるなら client は接続済みなはず。
      throw new Error("client === undefined");
    }

    // このブラウザーを見ているユーザー or スタッフの identity
    const myIdentity = client.user.identity;

    // 参加している conversations の情報に変化があったときに未読件数を更新する。
    // 自分自身の送信メッセージは既読処理がシビアなので無視する。
    // https://github.com/labox-inc/sion-web/pull/2827
    const messages = await conversation.getMessages(
      1,
      conversation.lastMessage?.index,
    );
    const lastMessage = messages.items.pop();
    if (lastMessage?.author === myIdentity) {
      return;
    }

    this.updateUnreadMessages(client);
  };

  private updateUnreadMessages = async (client: Client): Promise<void> => {
    // 同時に呼ばれてもいいようにする。
    if (this.isUpdatingUnreadMessages) {
      return;
    }

    this.isUpdatingUnreadMessages = true;
    try {
      const unreadMessagesCount = await getUnreadMessagesCount(
        client,
        this.visibleConversationSids,
        this.identity,
      );
      this.unreadMessagesCount = unreadMessagesCount;
      this.unreadMessagesCountCallbacks?.forEach((callback) => {
        callback(unreadMessagesCount);
      });
    } finally {
      this.isUpdatingUnreadMessages = false;
    }
  };

  watchUnreadMessagesCount = async (
    callback: UnreadMessagesCountCallback,
    sids: string[],
  ): Promise<void> => {
    // まだイベントが設定されていない。
    if (this.unreadMessagesCountCallbacks === undefined) {
      this.unreadMessagesCountCallbacks = new Set<UnreadMessagesCountCallback>([
        callback,
      ]);
      this.visibleConversationSids = sids;
      const client = await this.getClient();
      client.addListener("conversationUpdated", this.onConversationUpdated);

      // 現在の未読数
      const unreadMessagesCount = (this.unreadMessagesCount =
        await getUnreadMessagesCount(
          client,
          this.visibleConversationSids,
          this.identity,
        ));
      this.unreadMessagesCountCallbacks?.forEach((callback) => {
        callback(unreadMessagesCount);
      });
      return;
    }

    // conversation が減ることはない想定。
    if (this.visibleConversationSids.length < sids.length) {
      // 増えた！
      console.warn(
        "visibleConversationSids change",
        this.visibleConversationSids,
        sids,
      );
      this.visibleConversationSids = sids;
    }

    this.unreadMessagesCountCallbacks.add(callback);

    // 現在の未読数がわかっていたら初回の callback
    if (this.unreadMessagesCount !== undefined) {
      callback(this.unreadMessagesCount);
    }
  };

  unwatchUnreadMessagesCount = (
    callback: UnreadMessagesCountCallback,
  ): void => {
    this.unreadMessagesCountCallbacks?.delete(callback);
  };

  // initialized 前に複数回呼ばれても大丈夫なように、 Promise で返す。
  private createClient = async (): Promise<Client> => {
    const client = new Client(this.accessToken || "");
    return new Promise((resolve, reject) => {
      client.on("stateChanged", (state) => {
        if (state == "initialized") {
          this.initializedClient = client;
          resolve(client);
        } else if (state == "failed") {
          // accessToken の期限切れはここに来るっぽい。
          reject(new Error("connection state failed"));
        }
      });
    });
  };

  getConversation = async (conversationSid: string): Promise<Conversation> => {
    const client = await this.getClient();
    let page = await client.getSubscribedConversations();
    for (;;) {
      const result = page.items.find((item) => item.sid === conversationSid);
      if (result) {
        return result;
      }

      if (!page.hasNextPage) {
        throw new Error(
          `トークが見つかりません。Conversation: '${conversationSid}'`,
        );
      }
      page = await page.nextPage();
    }
  };
}

export const getTwilioConnection = (): TwilioConnection => {
  const twilioConnection = window.TwilioConnection;
  if (twilioConnection instanceof TwilioConnection) {
    return twilioConnection;
  }

  throw new Error("not found TwilioConnection");
};
