import React, { useRef, useEffect, useMemo, useState } from "react";
import { ErrorBoundary } from "./ErrorBoundary";
import { ImageMessageProvider } from "components/ImageMessageContext";
import { beginningOfDay } from "components/chatFunctions";
import prettyBytes from "pretty-bytes";
import { useImageMessageContext } from "components/ImageMessageContext";
import { getConversationMessageHtml } from "./getConversationMessageHtml";
import { pad } from "components/chatFunctions";
import { useAsyncCatchError } from "./useAsyncCatchError";
import Loading from "./Loading";
import useScroll from "./useScroll";

/* eslint-disable @typescript-eslint/no-require-imports */
const pdfIcon = require("../images/icon-pdf.svg");
/* eslint-enable @typescript-eslint/no-require-imports */

// view の show がセットするパラメータ
interface Props {
  endpoint: string;
}

// テキストメッセージ
interface TextMessage {
  type: "text";
  body: string;
  dateCreated: number;
}

// 添付ファイルメッセージ
interface MediaMessage {
  type: "media";
  attacheMedia: BroadcastMedia;
  dateCreated: number;
}

type BroadcastMessage = TextMessage | MediaMessage;

// 添付ファイル詳細
interface BroadcastMedia {
  fileName: string;
  fileSize: number;
  mediaUrl: string;
  contentType: string;
}

interface ChatMessageProps {
  msg: BroadcastMessage;
}

const LOAD_ERROR_MESSAGE = "メディアの取得に失敗しました";

/**
 * 与えられた日付から日付切り替わる境界で表示する文字列を作成
 *
 * @param date 文字列で表示したい日付
 */
function chatDateString(date: Date): string {
  const today = beginningOfDay(new Date());
  if (today.getTime() === date.getTime()) {
    return "今日";
  }

  today.setDate(today.getDate() - 1);
  if (today.getTime() === date.getTime()) {
    return "昨日";
  }

  return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
}

const BroadcastMessages: React.FC<Props> = (props: Props) => {
  const { endpoint } = props;

  const [page, setPage] = useState<number>(1);
  const [messages, setMessages] = useState<BroadcastMessage[]>([]);
  const messagesRef = useRef(messages);

  const [loading, setLoading] = useState<boolean>(true);
  const [loadingPrev, setLoadingPrev] = useState<boolean>(false);
  const scrollBottom = useRef<HTMLDivElement>(null);
  const [newMessageCount, setNewMessageCount] = useState<number>(0);
  const runAsync = useAsyncCatchError();

  // 過去メッセージ読み込み時の合計縦幅計算用
  const oldMessages = useRef<HTMLDivElement>(null);

  // メッセージを取得する
  const fetchMessages = async (page: number): Promise<BroadcastMessage[]> => {
    const res = fetch(`/mypage/talkrooms/${endpoint}?page=${page}`);
    const data: Promise<BroadcastMessage[]> = (await res).json();
    return data;
  };

  // メッセージ合間に挟んで表示する日付
  const chatTimes: { [index: number]: Date } = useMemo(() => {
    const times: { [index: number]: Date } = {};
    if (messages.length === 0) return times;

    let prev = beginningOfDay(new Date(messages[0].dateCreated));
    times[0] = prev;
    messages.slice(1).forEach((msg, index) => {
      const d = beginningOfDay(new Date(msg.dateCreated));
      if (d.getTime() > prev.getTime()) {
        times[index] = d;
        prev = d;
      }
    });
    return times;
  }, [messages]);

  const scrollToBottom = (): void => {
    scrollBottom.current?.scrollIntoView({
      behavior: "auto",
      block: "start",
    });
  };

  // 上までスクロールした時に過去のメッセージをロードする。
  const scroll = useScroll();
  useEffect(() => {
    if (scroll.y || scroll.direction !== "up" || loadingPrev) return;
    setLoadingPrev(true);
    setPage(page + 1);
  }, [scroll]);

  // ページ設定時(メッセージ取得)
  useEffect(() => {
    runAsync(async () => {
      const m = await fetchMessages(page);
      setNewMessageCount(m.length);
      if (loadingPrev) {
        // 古い発言取得時
        const newMessages = [...m, ...messagesRef.current];
        setMessages(newMessages);
      } else {
        // 初回取得時
        setMessages(m);
        setLoading(false);
      }
    });
  }, [page]);

  // ローディングが完了したとき
  useEffect(() => {
    scrollToBottom();
  }, [loading]);

  // メッセージ総数が変動した時
  useEffect(() => {
    messagesRef.current = messages;

    // 過去発言のロードによる更新の場合、スクロール位置移動
    if (loadingPrev) {
      if (oldMessages !== null) {
        const totalHeight =
          oldMessages?.current?.getBoundingClientRect().height;
        window.scrollTo({ top: totalHeight });
      }
      setLoadingPrev(false);
    }
  }, [messages]);

  return (
    <Loading loading={loading || loadingPrev}>
      <ImageMessageProvider>
        <div className="talkroom">
          <div className="messages">
            <div ref={oldMessages}>
              {messages.slice(0, newMessageCount).map((msg, index) => (
                <div key={index}>
                  {chatTimes[index] ? (
                    <div className="text-center mb-4 font-weight-bold">
                      {chatDateString(chatTimes[index])}
                    </div>
                  ) : null}
                  <ChatMessage msg={msg} />
                </div>
              ))}
            </div>
            {messages.slice(newMessageCount).map((msg, index) => (
              <div key={index}>
                {chatTimes[index] ? (
                  <div style={{ textAlign: "center" }}>
                    {chatDateString(chatTimes[index])}
                  </div>
                ) : null}
                <ChatMessage msg={msg} />
              </div>
            ))}
            <div ref={scrollBottom} />
          </div>
        </div>
      </ImageMessageProvider>
    </Loading>
  );
};

const BroadcastMessgesApp: React.FC<Props> = (props: Props) => {
  return (
    <ErrorBoundary>
      <BroadcastMessages {...props} />
    </ErrorBoundary>
  );
};

export default BroadcastMessgesApp;

const isImageContentType = (contentType: string): boolean => {
  switch (contentType) {
    case "image/jpeg":
    case "image/jpg":
    case "image/png":
    case "image/git":
      return true;
    default:
      return false;
  }
};

const isPdfContentType = (contentType: string): boolean => {
  return contentType == "application/pdf";
};

const ChatMessage: React.FC<ChatMessageProps> = (props: ChatMessageProps) => {
  const { msg } = props;

  return (
    <div className="d-flex justify-content-start mb-4">
      <Body msg={msg} />
      <div className="created-time align-self-end text-nowrap">
        {pad(new Date(msg.dateCreated).getHours())}:
        {pad(new Date(msg.dateCreated).getMinutes())}
      </div>
    </div>
  );
};

const Body: React.FC<ChatMessageProps> = (props: ChatMessageProps) => {
  const { msg } = props;

  // テキスト
  if (msg.type === "text") {
    return (
      <div
        className={`message-body mx-3 receive`}
        dangerouslySetInnerHTML={getConversationMessageHtml(msg.body)}
      />
    );
  }

  // 画像
  if (isImageContentType(msg.attacheMedia.contentType)) {
    return <ImageBody msg={msg} />;
  }

  // PDF
  if (isPdfContentType(msg.attacheMedia.contentType)) {
    return <PdfMedia msg={msg} />;
  }

  // 対応していないメディアだった。
  return <div>{LOAD_ERROR_MESSAGE}</div>;
};

const ImageBody: React.FC<ChatMessageProps> = (props: ChatMessageProps) => {
  const { msg } = props;

  if (msg.type === "media") {
    return <ImageMedia url={msg.attacheMedia.mediaUrl} />;
  } else {
    return null;
  }
};

interface ImageMediaProps {
  url: string;
}

const ImageMedia: React.FC<ImageMediaProps> = (props: ImageMediaProps) => {
  const IMG_SIZE = "240px";
  const { url } = props;
  const { setUrl } = useImageMessageContext();
  const style = useMemo(() => {
    return {
      box: {
        width: url === undefined ? IMG_SIZE : "auto",
        height: IMG_SIZE,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      mediaMessage: {
        maxWidth: IMG_SIZE,
        maxHeight: IMG_SIZE,
        borderRadius: "4px",
      },
    };
  }, [url]);

  return (
    <div style={style.box}>
      {url === undefined ? (
        <span>
          <i className="fa fa-5x fa-spinner fa-spin" />
        </span>
      ) : (
        <img
          src={url}
          className={"mx-3"}
          style={style.mediaMessage}
          onClick={() => {
            setUrl(url);
          }}
        />
      )}
    </div>
  );
};

// ポップアップブロックを回避して PDF を開くためのモーダルを表示する。
const openShowPdfModal = async (
  filename: string,
  mediaUrl: string,
): Promise<void> => {
  const modal = document.getElementById("show_pdf_modal");
  const modalBody = document.getElementById("show_pdf_modal_body");
  const modalButton = document.getElementById("show_pdf_modal_button");
  if (
    !(
      modal instanceof HTMLDivElement &&
      modalBody instanceof HTMLDivElement &&
      modalButton instanceof HTMLButtonElement
    )
  ) {
    console.error("show_pdf_modal がない!");
    return;
  }

  // URL 取得前に中身を消してモーダルを開いておく。
  modalBody.textContent = "";
  modalButton.classList.add("disabled");
  modalButton.onclick = null;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const jQuery = (window as any).jQuery as any;
  jQuery(modal).modal("show");

  modalBody.textContent = `"${filename}" を開きますか?`;
  modalButton.classList.remove("disabled");
  modalButton.onclick = () => {
    // ポップアップブロックされないように、 onclick で直接 window.open する。
    window.open(mediaUrl, "_blank");
  };
};

const PdfMedia: React.FC<ChatMessageProps> = (props: ChatMessageProps) => {
  const { msg } = props;

  if (msg.type !== "media") {
    return null;
  }

  const onClick = (): void => {
    const openUrl = window.MobilicoApp?.openUrl;
    if (openUrl) {
      openUrl(msg.attacheMedia.mediaUrl);
    } else {
      openShowPdfModal(msg.attacheMedia.fileName, msg.attacheMedia.mediaUrl);
    }
  };

  return (
    <button
      onClick={onClick}
      className="btn btn-light text-left bg-light p-3 rounded mx-3 flex-grow-1"
    >
      <div className="d-flex">
        <img src={pdfIcon} width={48} />
        <div className="ml-2">
          {msg.attacheMedia.fileName}
          <br />
          サイズ：{prettyBytes(msg.attacheMedia.fileSize)}
        </div>
      </div>
    </button>
  );
};
