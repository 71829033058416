import { Controller } from "@hotwired/stimulus";

/**
 * controller: "criteo-phone-call-event",
 * action: "criteo-phone-call-event#click",
 * criteo_phone_call_event_hashed_email_value: hashed_email,
 * criteo_phone_call_event_product_id_value: ,
 * criteo_phone_call_event_product_price_value: ,
 * criteo_phone_call_event_id_value:
 */
export default class extends Controller {
  static values = {
    id: String,
    hashedEmail: String,
    productId: Number,
    productPrice: Number,
  };

  click() {
    const events = this.#buildEvents();
    // Criteo Phone Call Sales Tag
    window.criteo_q = window.criteo_q || [];
    window.criteo_q.push(...events);
  }

  #buildEvents() {
    const product =
      this.productIdValue > 0
        ? {
            id: this.productIdValue,
            price: this.productPriceValue,
            quantity: 1,
          }
        : { id: 0, price: 0, quantity: 0 };

    const deviceType = /iPad/.test(navigator.userAgent)
      ? "t"
      : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(
            navigator.userAgent,
          )
        ? "m"
        : "d";

    return [
      { event: "setAccount", account: 100206 },
      { event: "setSiteType", type: deviceType },
      {
        event: "setEmail",
        email: this.hashedEmailValue,
        hash_method: "sha256",
      },
      { event: "trackTransaction", id: this.idValue, item: [product] },
    ];
  }
}
