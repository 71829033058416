interface QrReaderErrorDetail {
  message?: string;
  videoInputDeviceNotFound?: boolean;
}

export function dispatchQrReaderErrorEvent(detail?: QrReaderErrorDetail): void {
  const event = new CustomEvent("sion-QrReaderError", { detail });
  document.dispatchEvent(event);
}

interface ReadConversationDetail {
  sid: string;
  index: number;
}

export function dispatchReadConversationEvent(
  detail: ReadConversationDetail,
): void {
  const event = new CustomEvent("sion-ReadConversation", { detail });
  document.dispatchEvent(event);
}
