import { Controller } from "@hotwired/stimulus";
import { loadImageToBlob } from "../../shared/loadImageToBlob";
import { createSameNameHiddenInput } from "../../shared/createSameNameHiddenInput";

/** 選択した画像ファイルのサイズを変える。
 *
 * 使い方:
 * 1. form.file_field に `data: {image_resize_preview_target: "fileInput"}` を追加。
 * 2. プレビューとして画像を追加する要素に `data-image-resize-preview-target="container"` を追加。
 *    image-resize-preview.container は画像の preview 実行時に子要素が全て取り除かれるので注意
 * 3. container の要素の中身になるテンプレート `<template data-image-resize-preview-target="template">` を追加。
 * 4. (必要なら) プレビュー時に削除する要素(複数可)に `data-image-resize-preview-target="removeField"` を追加。
 *    エラー時にファイルの選択状態が保持されるように使う hidden_field 等に使用できる。
 *
 * form の送信は blob-upload-form で。
 */
export default class extends Controller {
  static targets = ["fileInput", "container", "template", "removeField"];

  connect() {
    this.fileInputTarget.addEventListener("change", (_event) => {
      this.#fileSelect();
    });
  }

  // ファイルを削除する。
  delete() {
    // プレビューを消す。
    removeAllChildren(this.containerTarget);

    // blob-upload-form 用の `data-blob-url` を削除する。
    const fileInput = this.fileInputTarget;
    if (fileInput.dataset.blobUrl) {
      URL.revokeObjectURL(fileInput.dataset.blobUrl);
    }
    fileInput.dataset.blobUrl = "";

    // 空文字列の hidden_field を作る。
    createSameNameHiddenInput(fileInput, "");

    this.element.dataset.formStatus = "blank";
  }

  #fileSelect() {
    const fileInput = this.fileInputTarget;
    if (fileInput.files.length < 1) {
      return;
    }
    const file = fileInput.files[0];

    // 同じファイルを選択した時にまた change イベントが来るように、ファイルの選択を解除する。
    fileInput.value = "";

    // どの画面の画像をリサイズするか判定するためにidを抽出
    const elementId = fileInput.id || "";
    this.#createSmallBlob(file, elementId);
  }

  /**
   * @param {File} file
   * @param {String} elementId
   */
  async #createSmallBlob(file, elementId) {
    let maxWidth = 1920;
    let maxHeight = 1920;

    // 車検証登録画像の場合は長辺2880pxにリサイズ
    if (elementId === "exhibited_car_car_inspection_certificate_photo") {
      maxWidth = 2880;
      maxHeight = 2880;
    }
    const blob = await loadImageToBlob(
      file,
      {
        maxWidth: maxWidth,
        maxHeight: maxHeight,
        canvas: true,
        meta: true,
        orientation: true,
        contain: true,
      },
      "image/jpeg",
      0.85,
    );
    const blobUrl = URL.createObjectURL(blob);

    this.#preview(blobUrl, this.containerTarget, this.templateTarget);
    this.removeFieldTargets.forEach((x) => x.remove());

    const fileInput = this.fileInputTarget;
    if (fileInput.dataset.blobUrl) {
      URL.revokeObjectURL(fileInput.dataset.blobUrl);
    }
    fileInput.dataset.blobUrl = blobUrl;
    this.element.dataset.formStatus = "preview";
  }

  #preview(url, container, template) {
    removeAllChildren(container);

    const content = template.content.cloneNode(true);
    const images = content.querySelectorAll("img");
    images.forEach((imageTag) => {
      imageTag.src = url;
    });

    container.appendChild(content);
  }
}

function removeAllChildren(node) {
  while (node.hasChildNodes()) {
    node.removeChild(node.lastChild);
  }
}
