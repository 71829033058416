import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["section", "submitButton", "frame"];

  initialize() {
    this.frame = this.frameTarget.cloneNode(true);
  }

  connect() {
    this.#toggleSubmitButton();
  }

  validate() {
    this.#toggleSubmitButton();
  }

  closeModal() {
    window.requestAnimationFrame(() => {
      this.frameTarget.replaceWith(this.frame.cloneNode(true));
    });
  }

  #toggleSubmitButton() {
    const allSectionsValid = this.sectionTargets.every((section) => {
      const checkboxes = section.querySelectorAll('input[type="checkbox"]');
      const radios = section.querySelectorAll('input[type="radio"]');
      const hasCheckedCheckbox = Array.from(checkboxes).some(
        (input) => input.checked,
      );
      const hasCheckedRadio = Array.from(radios).some((input) => input.checked);
      return checkboxes.length ? hasCheckedCheckbox : hasCheckedRadio;
    });

    if (allSectionsValid) {
      this.submitButtonTarget.removeAttribute("disabled");
    } else {
      this.submitButtonTarget.setAttribute("disabled", true);
    }
  }
}
