import { Controller } from "@hotwired/stimulus";
import { getFileSizeForHuman } from "../../shared/getFileSizeForHuman";
import { readFileAsDataURL } from "../../shared/readFileAsDataURL";

const FILE_MAX_SIZE = 1024 * 1024 * 5; // 5MB

/** トーク一斉送信で添付されたファイルの情報を表示する
 *  添付ファイルの以下を表示
 *   - ファイル名
 *   - ファイルサイズ
 *   - 画像ファイルの場合は画像プレビュー
 *
 * 使用例:
 * ```
 * <div data-controller="messages-broadcast-images-form">
 *   <input data-messages-broadcast-images-form-target="fileInput" type="file">
 *   <span data-messages-broadcast-images-form-target="fileName">...</span>
 *   <span data-messages-broadcast-images-form-target="fileSize">...</span>
 *   <img data-messages-broadcast-images-form-target="previewImage">
 * </div>
 * ```
 */
export default class extends Controller {
  static targets = ["fileInput", "fileName", "fileSize", "previewImage"];

  connect() {
    this.fileInputTarget.addEventListener("change", (event) => {
      this.#update(event);
    });
    this.element.dataset.formStatus = "normal";
  }

  #update(event) {
    const files = event.target.files;
    if (files.length < 1) {
      return;
    }

    const file = files[0];
    if (file.size > FILE_MAX_SIZE) {
      alert(
        "ファイルサイズが大きすぎます。5MB以内のファイルを添付してください。",
      );
      return;
    }

    this.element.dataset.formStatus = "normal";
    this.element.classList.add("saved");

    const fileName = this.fileNameTarget;
    fileName.textContent = file.name;

    const fileSize = this.fileSizeTarget;
    fileSize.textContent = getFileSizeForHuman(file.size);

    this.#preview(file);
  }

  async #preview(file) {
    if (file.type == "application/pdf") {
      return;
    }

    const url = await readFileAsDataURL(file);
    this.previewImageTargets.forEach((img) => {
      img.src = url;
    });
  }
}
