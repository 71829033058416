import React from "react";
import { QRReaderFromVideo } from "./QRReaderFromVideo";
import { ErrorBoundary } from "./ErrorBoundary";

const QRCodeReader: React.FC = () => {
  return (
    <QRReaderFromVideo
      onCompleteScan={(carInfo, carSpec) => {
        const params: { [k: string]: string } = {
          // `12/5` みたいな形式だと rails 側で扱いにくいので日付を追加する
          registration_date: `${carSpec.shodoKensaNengetsu.year}/${carSpec.shodoKensaNengetsu.month}/01`,
          specified_model_number: carSpec.katashikiSiteiBangou,
          classification_number: carSpec.ruibetsuKubunBangou,
          expired_car_inspection_date: `${carSpec.yuukouKikanManryoubi.year}/${carSpec.yuukouKikanManryoubi.month}/${carSpec.yuukouKikanManryoubi.day}`,
          number_plate_locale: carInfo.syaryouBangou.locale,
          number_plate_class_number: carInfo.syaryouBangou.classNumber,
          number_plate_hiragana: carInfo.syaryouBangou.hiragana,
          number_plate_number: carInfo.syaryouBangou.number,
          chassis_model_code: carInfo.syadaiBangou.frameKatashiki,
          chassis_number: carInfo.syadaiBangou.frameBangou,
          model_code: carSpec.katashiki,
        };

        const query = Object.keys(params)
          .map(
            (k) => encodeURIComponent(k) + "=" + encodeURIComponent(params[k]),
          )
          .join("&");
        location.href = `${location.pathname}/complete?${query}`;
      }}
    />
  );
};

const App: React.FC = () => {
  return (
    <ErrorBoundary>
      <QRCodeReader />
    </ErrorBoundary>
  );
};

export default App;
