import React, { useEffect, useState } from "react";
import { ErrorBoundary } from "./ErrorBoundary";
import { Client, Conversation, Message } from "@twilio/conversations";
import MessageItem, { MessageUser } from "components/MessageItem";
import {
  getUnreadMessagesCountByConversation,
  sort,
} from "components/chatFunctions";
import Skeleton from "react-loading-skeleton";
import { useAsyncCatchError } from "./useAsyncCatchError";
import { getTwilioConnection } from "shared/TwilioConnection";

interface Props {
  conversationSidAndUser: { [sid: string]: MessageUser };
  accessToken: string;
  messagesPath: string;
  storeStaffDomainPath: string;
  identity: string | null;
}

type ConversationAndLastMessage = {
  conversation: Conversation;
  message: Message;
  hasUnread: boolean;
};

type ConversationData = {
  conversation: Conversation;
  messageUser: MessageUser;
  hasUnread: boolean;
};

/**
 * ホーム画面で表示するチャット一覧
 * 各チャットには最後のメッセージと未読アイコンを表示
 */
const ChatLastMessageList: React.FC<Props> = (props: Props) => {
  const {
    conversationSidAndUser,
    accessToken,
    messagesPath,
    storeStaffDomainPath,
    identity,
  } = props;

  const [conversations, setConversations] = useState<
    ConversationAndLastMessage[]
  >([]);
  const [loading, setLoading] = useState<boolean>(true);
  const runAsync = useAsyncCatchError();

  /**
   * TODO
   * twilio sdkの初期化〜conversation取得まで、店舗スタッフメッセージ画面(ChatMessageList)と同様
   * 未読メッセージの有無によって最終メッセージを取得するかどうかが異なる
   * 共通化を考える
   */
  useEffect(() => {
    runAsync(async () => {
      const client: Client = await getTwilioConnection()
        .setAccessToken(accessToken)
        .getClient();

      // サーバーで管理しているconversationと未読をtwilioから参照
      const getConversation: (
        client: Client,
        sid: string,
        messageUser: MessageUser,
      ) => Promise<ConversationData> = async (
        client: Client,
        sid: string,
        messageUser: MessageUser,
      ) => {
        const conversation = await client.getConversationBySid(sid);
        const count = await getUnreadMessagesCountByConversation(
          conversation,
          identity,
        );
        return {
          conversation,
          messageUser,
          hasUnread: Boolean(count > 0),
        };
      };

      const result = await Promise.all(
        Object.entries(conversationSidAndUser).map(([sid, messageUser]) =>
          getConversation(client, sid, messageUser),
        ),
      );
      const allConversations = await Promise.all(
        result.map((x) => {
          // ホーム画面でのチャット一覧なので未読があるチャットのみ最後のメッセージを取得
          if (x.hasUnread && x.conversation.lastMessage?.index !== undefined) {
            return x.conversation
              .getMessages(1, x.conversation.lastMessage.index)
              .then((paginator) => {
                const messages = paginator.items;
                return {
                  conversation: x.conversation,
                  message: messages.length > 0 ? messages[0] : null,
                  hasUnread: x.hasUnread,
                };
              });
          } else {
            return Promise.resolve({
              conversation: x.conversation,
              message: null,
              hasUnread: x.hasUnread,
            });
          }
        }),
      );

      setConversations(
        allConversations.filter(
          (c) => c.hasUnread && c.message,
        ) as ConversationAndLastMessage[],
      );
      setLoading(false);
    });
  }, []);

  return (
    <section className="section-message card">
      <header className="card-header">
        <h3 className="card-title line-height-lg">
          <i className=" icon-sm icon-message-fill-gray"></i> トーク
          <a
            className={"btn btn-outline-secondary float-right"}
            href={messagesPath}
            data-turbo={true}
          >
            一覧へ
          </a>
        </h3>
        {loading ? (
          <Skeleton count={3} />
        ) : (
          <p className="card-subtitle font-sm text-lightgray">
            {conversations.length === 0 ? (
              <>未読のトークルームはありません。</>
            ) : (
              <>
                <strong className="text-base font-base">
                  {conversations.length}名
                </strong>
                のお客様からメッセージが届いています
              </>
            )}
          </p>
        )}
      </header>
      <div className="card-body">
        {conversations.length === 0 || accessToken === "" ? null : (
          <ul className="list-group">
            {sort(conversations).map((conversation) => (
              <MessageItem
                message={conversation.message}
                user={conversationSidAndUser[conversation.conversation.sid]}
                unread={true}
                key={conversation.conversation.sid}
                storeStaffDomainPath={storeStaffDomainPath}
                type={"receive"}
              />
            ))}
          </ul>
        )}
      </div>
    </section>
  );
};

const ChatLastMessageListApp: React.FC<Props> = (props: Props) => {
  return (
    <ErrorBoundary>
      <ChatLastMessageList {...props} />
    </ErrorBoundary>
  );
};

export default ChatLastMessageListApp;
