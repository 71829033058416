import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
} from "react";
import { ErrorBoundary } from "./ErrorBoundary";
import {
  Conversation as ConversationType,
  Message,
  Participant,
} from "@twilio/conversations";
import TextareaAutosize from "react-textarea-autosize";
import { ChatMessage } from "components/ChatMessage";
import { beginningOfDay } from "components/chatFunctions";
import { ImageMessageProvider } from "components/ImageMessageContext";
import ImageMessageModal from "components/ImageMessageModal";
import { useAsyncCatchError } from "./useAsyncCatchError";
import { dispatchReadConversationEvent } from "./events";
import { Document, Page, pdfjs } from "react-pdf";
import { PDFDocumentProxy } from "pdfjs-dist/types/src/display/api";
import { getTwilioConnection } from "shared/TwilioConnection";
import Loading from "./Loading";
import useScroll from "./useScroll";
import { useContextMenu } from "react-contexify";

// PDF.js worker を使用する。
// https://github.com/wojtekmaj/react-pdf/tree/543e252b1c306027c9ff4b7f3dad89b9755c28a6#standard-browserify-and-others
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

// PDF.js の CVE-2024-4367 の脆弱性を回避するために eval を無効化する。
// https://github.com/advisories/GHSA-wgrm-67xf-hhpq
// https://github.com/wojtekmaj/react-pdf/#setting-up-react-pdf
const pdfjsOptions = {
  isEvalSupported: false,
};

interface Props {
  role: "customer" | "store_staff";
  accessToken: string;
  identity: string;
  conversationSid: string;
  enableShare: boolean;
  name: string;
  discarded: boolean;
}

interface SendImageMetadata {
  metadata: {
    width: number;
    height: number;
  };
}

// 1度にtwilioから取得するメッセージ数
// 初回と、スクロールして過去にさかのぼった時この件数ずつ取得
const MESSAGE_COUNT_PER_PAGE = 25;

// メッセージを送信できる文字数上限
const MESSAGE_LIMIT_SIZE = 2500;

// 画像選択ボタンのアイコン

/* eslint-disable @typescript-eslint/no-require-imports */
const uploadIcon = require("../images/upload.svg");
const sendMessageIcon = require("../images/send-message.svg");
const disableSendMessageIcon = require("../images/disable-send-message.svg");
/* eslint-enable @typescript-eslint/no-require-imports */

/**
 * 与えられた日付から日付切り替わる境界で表示する文字列を作成
 *
 * @param date 文字列で表示したい日付
 */
function chatDateString(date: Date): string {
  const today = beginningOfDay(new Date());
  if (today.getTime() === date.getTime()) {
    return "今日";
  }

  today.setDate(today.getDate() - 1);
  if (today.getTime() === date.getTime()) {
    return "昨日";
  }

  return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
}

/**
 * 未読件数からページあたりの取得件数を算出
 *
 * @param count 未読件数
 */
function pageSize(count: number | null): number {
  if (!count) return MESSAGE_COUNT_PER_PAGE;
  return MESSAGE_COUNT_PER_PAGE * Math.ceil(count / MESSAGE_COUNT_PER_PAGE);
}

const Conversation: React.FC<Props> = (props: Props) => {
  const {
    role,
    accessToken,
    identity,
    conversationSid,
    enableShare,
    name,
    discarded,
  } = props;

  // store_staffと相手のconversation
  const [conversation, setConversation] = useState<ConversationType>();

  // twilio sdk はカンバセーションオブジェクトにイベントリスナ登録する実装になる
  // 登録するコールバック内でuseStateで保持している既存メッセージ一覧を取得できないためuseRefを利用して回避
  // 参考 https://stackoverflow.com/questions/55265255/react-usestate-hook-event-handler-using-initial-state
  const [messages, setMessages] = useState<Message[]>([]);
  const messagesRef = useRef(messages);

  const [participants, setParticipants] = useState<Participant[]>([]);
  const [newMessage, setNewMessage] = useState<string>(
    localStorage.getItem(`${conversationSid}-message`) || "",
  );
  const [sendable, setSendable] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingPrev, setLoadingPrev] = useState<boolean>(false);
  const [uploading, setUploading] = useState<boolean>(false);
  const [unreadMessages, setUnreadMessages] = useState<Message[]>([]);
  const [oldMessagesLength, setOldMessagesLength] = useState<number>(
    MESSAGE_COUNT_PER_PAGE,
  );

  const chat = useRef<HTMLDivElement>(null);
  const scrollInit = useRef<HTMLDivElement>(null);
  const scrollBottom = useRef<HTMLDivElement>(null);
  const scroller = useRef<HTMLDivElement>(null);
  const anchor = useRef<number>();
  const runAsync = useAsyncCatchError();

  // 過去メッセージ読み込み時の合計縦幅計算用
  const oldMessages = useRef<HTMLDivElement>(null);

  // preview表示のFileオブジェクト
  const [previewFiles, setPreviewFiles] = useState<File[]>();
  const [previewFilepaths, setPreviewFilepaths] = useState<string[]>();

  // プレビュー用画像の URL.revokeObjectURL 呼ぶために previewImagePath はここで更新
  useEffect(() => {
    if (previewFiles) {
      setPreviewFilepaths(
        previewFiles.map((file) => URL.createObjectURL(file)),
      );
    } else if (previewFilepaths) {
      previewFilepaths.map((path) => URL.revokeObjectURL(path));
      setPreviewFilepaths(undefined);
    }
  }, [previewFiles, setPreviewFilepaths]);

  // メッセージ合間に挟んで表示する日付
  const chatTimes: { [index: number]: Date } = useMemo(() => {
    const times: { [index: number]: Date } = {};
    if (messages.length === 0) return times;

    let prev = beginningOfDay(messages[0].dateCreated);
    times[messages[0].index] = prev;
    messages.slice(1).forEach((msg) => {
      const d = beginningOfDay(msg.dateCreated);
      if (d.getTime() > prev.getTime()) {
        times[msg.index] = d;
        prev = d;
      }
    });
    return times;
  }, [messages]);

  const scrollToInit = (): void => {
    scrollInit.current?.scrollIntoView({
      behavior: "auto",
      block: "center",
    });
  };

  const scrollToBottom = (): void => {
    scrollBottom.current?.scrollIntoView({
      behavior: "auto",
      block: "start",
    });
  };

  const scrollToBottomIfNeeded = (): void => {
    // チャットの DOM が取得できない場合は何もしない。
    if (!chat.current) {
      return;
    }

    if (chat.current.getBoundingClientRect().bottom <= window.innerHeight) {
      scrollToBottom();
    }
  };

  // eslint-disable-next-line
  const handleAddMessage = (msg: Message) => {
    if (conversation === undefined) return;
    setMessages([...messagesRef.current, msg]);
    scrollToBottomIfNeeded();
    dispatchReadConversationEvent({ sid: conversationSid, index: msg.index });
  };

  // 上までスクロールした時に過去のメッセージをロードする。
  const { hideAll } = useContextMenu();
  const scroll = useScroll();
  useEffect(() => {
    // コンテキストメニューを削除
    hideAll();

    // スクロール条件を満たしていないとき即時 return
    if (
      !conversation ||
      scroll.y !== 0 || // スクロール位置が一番上じゃない
      loadingPrev || // ロード中
      !oldMessagesLength || // 既に最初のメッセージまでロードしている
      !anchor.current
    ) {
      return;
    }

    if (anchor.current) anchor.current--;
    setLoadingPrev(true);
    runAsync(() =>
      conversation
        .getMessages(MESSAGE_COUNT_PER_PAGE, anchor.current)
        .then((paginator) => {
          setMessages([...paginator.items, ...messagesRef.current]);
          setOldMessagesLength(paginator.items.length);
          const top = oldMessages.current?.offsetTop;
          if (top) scroller.current?.scrollTo({ top: top - 50 });
        })
        .finally(() => setLoadingPrev(false)),
    );
  }, [scroll]);

  // 初回
  useEffect(() => {
    runAsync(async () => {
      const twilioConnection = getTwilioConnection();
      twilioConnection.setAccessToken(accessToken);

      const cnv = await twilioConnection.getConversation(conversationSid);
      setConversation(cnv);

      // 送ったメッセージが既読になったときに参加者の情報が更新される
      cnv.on("participantUpdated", (_) => {
        if (cnv.sid !== conversationSid) return;
        cnv.getParticipants().then((currentParticipants) => {
          setParticipants(currentParticipants);
        });
      });
    });
  }, []);

  // conversation取得時
  useEffect(() => {
    if (conversation === undefined) return;
    conversation.addListener("messageAdded", handleAddMessage);
    runAsync(async () => {
      const unreadCount = await conversation.getUnreadMessagesCount();
      const paginator = await conversation.getMessages(pageSize(unreadCount));
      const currentParticipants = await conversation.getParticipants();
      setMessages(paginator.items);

      const newUnreadMessage = paginator.items
        .filter((x) => x.author !== identity)
        .filter(
          (x) =>
            conversation.lastReadMessageIndex &&
            conversation.lastReadMessageIndex < x.index,
        );
      if (conversation.lastReadMessageIndex) {
        setUnreadMessages(newUnreadMessage);
      }

      // チャット参加者の情報を更新
      setParticipants(currentParticipants);
      const lastIndex = paginator.items.length - 1;
      if (lastIndex >= 0) {
        const lastMessage = paginator.items[lastIndex];
        dispatchReadConversationEvent({
          sid: conversationSid,
          index: lastMessage.index,
        });
      }
      setLoading(false);
    });
  }, [conversation]);

  // ローディングが完了したとき
  useEffect(() => {
    if (unreadMessages.length) {
      scrollToInit();
    } else {
      scrollToBottom();
    }
  }, [loading]);

  // メッセージ総数が変動した時
  useEffect(() => {
    messagesRef.current = messages;
    // pagingロード用のアンカー位置保持
    anchor.current = messages[0]?.index;

    conversation?.setAllMessagesRead();
  }, [messages]);

  // 送信メッセージを編集したとき
  useEffect(() => {
    if (
      newMessage.replace(/\r?\n/g, "").length === 0 || // 改行だけの文章は送信させない。
      newMessage.length > MESSAGE_LIMIT_SIZE ||
      conversation === undefined
    ) {
      setSendable(false);
    } else {
      setSendable(true);
    }
  }, [newMessage, conversation]);

  // 送信メッセージの一時保存
  useEffect(() => {
    if (role === "store_staff") {
      if (newMessage.replace(/\r?\n/g, "").length) {
        // メッセージの保存
        localStorage.setItem(`${conversationSid}-message`, newMessage);
      } else {
        // メッセージの削除
        localStorage.removeItem(`${conversationSid}-message`);
      }
    }
  }, [newMessage]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    if (!sendable) {
      return;
    }
    runAsync(async () => {
      await conversation?.sendMessage(newMessage);
      setNewMessage("");
      localStorage.removeItem(`${conversationSid}-message`);
    });
  };

  const handlePreviewImage = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      e.preventDefault();
      if (e.target.files === null) return;
      setPreviewFiles(Array.from(e.target.files));
    },
    [],
  );

  const handleSendImage = useCallback(
    () =>
      runAsync(async (): Promise<void> => {
        try {
          if (conversation === undefined || previewFiles === undefined) return;
          setUploading(true);
          setPreviewFiles(undefined);

          for (let i = 0; i < previewFiles.length; i++) {
            const isImage = previewFiles[i].type.includes("image");
            const formData = new FormData();
            formData.append("file", previewFiles[i]);
            formData.append("filename", previewFiles[i].name);
            formData.append("contentType", previewFiles[i].type);

            if (isImage) {
              const metadata = await new Promise<SendImageMetadata>((res) => {
                const img = new Image();
                img.src = URL.createObjectURL(previewFiles[i]);
                img.addEventListener("load", () => {
                  const [width, height] = [img.naturalWidth, img.naturalHeight];
                  URL.revokeObjectURL(img.src);
                  res({ metadata: { width, height } });
                });
              });
              await conversation.sendMessage(formData, metadata);
            } else {
              await conversation.sendMessage(formData);
            }
          }

          setUploading(false);
        } catch (err) {
          console.error("画像ファイルの送信に失敗しました", err);
          setUploading(false);
          return Promise.reject(err);
        }
      }),
    [conversation, previewFiles],
  );

  return (
    <>
      <Loading loading={loading || (role === "store_staff" && loadingPrev)} />
      <ImageMessageProvider>
        {previewFilepaths && previewFiles ? (
          <ImageMessageSendPreview
            files={previewFiles}
            filepaths={previewFilepaths}
            onSend={handleSendImage}
            onCancel={() => setPreviewFiles(undefined)}
          />
        ) : null}
        <div ref={chat} className="talkroom">
          <div className="messages" ref={scroller}>
            {messages.slice(0, oldMessagesLength).map((msg) => (
              <div key={msg.index}>
                {unreadMessages[0] === msg && (
                  <div className={`unread-divider`} ref={scrollInit}>
                    ここから未読
                  </div>
                )}
                {chatTimes[msg.index] && (
                  <div className="text-center mb-3 font-weight-bold">
                    {chatDateString(chatTimes[msg.index])}
                  </div>
                )}
                <ChatMessage
                  msg={msg}
                  type={msg.author === identity ? "give" : "receive"}
                  participants={participants}
                  enableShare={enableShare}
                  name={name}
                />
              </div>
            ))}
            <div ref={oldMessages} />
            {messages.slice(oldMessagesLength).map((msg) => (
              <div key={msg.index}>
                {chatTimes[msg.index] ? (
                  <div className="text-center mb-3 font-weight-bold">
                    {chatDateString(chatTimes[msg.index])}
                  </div>
                ) : null}
                <ChatMessage
                  key={msg.index}
                  msg={msg}
                  type={msg.author === identity ? "give" : "receive"}
                  participants={participants}
                  enableShare={enableShare}
                  name={name}
                />
              </div>
            ))}
            <div ref={scrollBottom} />
          </div>

          {discarded ? null : (
            <form className="text-area" onSubmit={handleSubmit}>
              <div className="file">
                {uploading ? (
                  <span>
                    <i className="fa fa-lg fa-spinner fa-spin" />
                  </span>
                ) : (
                  <label>
                    <img src={uploadIcon} />
                    <input
                      type="file"
                      accept="image/*, application/pdf"
                      multiple
                      onClick={(e) => (e.currentTarget.value = "")}
                      onChange={(e) => handlePreviewImage(e)}
                    />
                  </label>
                )}
              </div>

              <TextareaAutosize
                className="form-control"
                minRows={1}
                maxRows={5}
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                placeholder="メッセージを入力"
              />

              <input
                type="image"
                src={sendable ? sendMessageIcon : disableSendMessageIcon}
                className="send"
              />
            </form>
          )}
        </div>
      </ImageMessageProvider>
    </>
  );
};

const ConversationApp: React.FC<Props> = (props: Props) => {
  return (
    <ErrorBoundary>
      <Conversation {...props} />
    </ErrorBoundary>
  );
};

export default ConversationApp;

// 画像送信前のモーダルプレビュー
const ImageMessageSendPreview = ({
  files,
  filepaths,
  onSend,
  onCancel,
}: {
  files: File[];
  filepaths: string[];
  onSend: () => void;
  onCancel: () => void;
}): JSX.Element => {
  const [document, setDocument] = useState<Record<
    string,
    PDFDocumentProxy
  > | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [currentFileIdx, setCurrentFile] = useState<number>(0);

  function onDocumentLoadSuccess(doc: PDFDocumentProxy): void {
    setDocument((prev) => ({ ...prev, [files[currentFileIdx].name]: doc }));
    setCurrentPage(1);
  }

  function changePage(offset: number): void {
    setCurrentPage(currentPage + offset);
  }

  function movePreviousPage(): void {
    if (currentPage > 1) {
      changePage(-1);
    }
  }

  function moveNextPage(name: string): void {
    if (canMoveNextPage(name)) {
      changePage(1);
    }
  }

  function canMoveNextPage(name: string): boolean {
    if (document === null) return false;
    return currentPage < document[name].numPages;
  }

  function moveNextFile(): void {
    setCurrentPage(1);
    if (currentFileIdx < files.length - 1) {
      setCurrentFile((c) => c + 1);
    } else {
      setCurrentFile(0);
    }
  }

  function movePreviousFile(): void {
    setCurrentPage(1);
    if (currentFileIdx > 0) {
      setCurrentFile((c) => c - 1);
    } else {
      setCurrentFile(files.length - 1);
    }
  }

  return (
    <>
      <div className="fade modal-backdrop show" />
      <ImageMessageModal>
        <div className="modal-header d-flex justify-content-end align-items-center">
          <div className="flex-grow-1 d-flex align-items-center">
            <a onClick={movePreviousFile}>
              <i className="far fa-arrow-alt-circle-left"></i>
            </a>
            <div
              className="h5 mx-1 mb-0 text-truncate"
              style={{ maxWidth: "40vw" }}
            >
              {files.length > 1 && (
                <span className="mr-1">
                  ({currentFileIdx + 1}/{files.length})
                </span>
              )}
              <span>{files[currentFileIdx].name}</span>
            </div>
            <a onClick={moveNextFile}>
              <i className="far fa-arrow-alt-circle-right"></i>
            </a>
          </div>

          {document && document[files[currentFileIdx].name] && (
            <>
              <span className="text-center mr-3 text-nowrap">
                <span className="h1 mb-0">{currentPage}</span>
                <span className="mx-1">/</span>
                {document[files[currentFileIdx].name]?.numPages}
              </span>
              <a onClick={movePreviousPage}>
                <i className={"mr-1 far fa-2x fa-arrow-alt-circle-left"}></i>
              </a>
              <a onClick={() => moveNextPage(files[currentFileIdx].name)}>
                <i className={"far fa-2x fa-arrow-alt-circle-right"}></i>
              </a>
            </>
          )}
        </div>
        <div className="modal-body">
          {files[currentFileIdx].type.includes("pdf") ? (
            <Document
              file={files[currentFileIdx]}
              onLoadSuccess={onDocumentLoadSuccess}
              options={pdfjsOptions}
            >
              <Page pageNumber={currentPage} />
            </Document>
          ) : (
            <div className="text-center">
              <img className="img-fluid" src={filepaths[currentFileIdx]} />
            </div>
          )}
        </div>
        <div className="modal-footer p-2 mx-2 d-flex justify-content-center">
          <a className="btn btn-outline-dark px-4" onClick={onCancel}>
            キャンセル
          </a>
          <a className="btn btn-outline-secondary px-5 ml-3" onClick={onSend}>
            <span>送信</span>
            {files.length > 1 && (
              <span className="ml-1">({files.length} 項目)</span>
            )}
          </a>
        </div>
      </ImageMessageModal>
    </>
  );
};
