import React from "react";

// 新しい typescript でエラーが出るので、回避する。
// https://github.com/streamich/react-use/issues/2074#issuecomment-982044510
// import { useWindowSize } from "react-use";
import useWindowSize from "react-use/lib/useWindowSize";

type Props = {
  videoElementID: string;
  width: number | string;
  height: number | string;
};
type State = {
  height: number | string;
};

interface ExternalProps {
  style?: React.CSSProperties;
}
export interface InjectedProps {
  windowSize: { width: number; height: number };
}

export const windowSizeHOC =
  () =>
  <OriginalProps extends object>(
    WrappedComponent: React.ComponentType<OriginalProps & InjectedProps>,
  ) => {
    type ResultProps = OriginalProps & ExternalProps;

    const WindowSizeHOC: React.FC<ResultProps> = (props: ResultProps) => {
      const windowSize = useWindowSize();

      return (
        <div>
          <WrappedComponent {...props} {...{ windowSize }} />
        </div>
      );
    };
    WindowSizeHOC.displayName = `WindowSizeHOC(${WrappedComponent.displayName})`;

    return WindowSizeHOC;
  };

class VideoCanvasBase extends React.Component<Props & InjectedProps, State> {
  videoElementRef: React.RefObject<HTMLVideoElement>;
  canvasElementRef: React.RefObject<HTMLCanvasElement>;
  requestAnimationFrameID: number | null = null;

  state: State = { height: 0 };

  constructor(props: Props & InjectedProps) {
    super(props);
    this.videoElementRef = React.createRef();
    this.canvasElementRef = React.createRef();
  }

  componentDidMount(): void {
    const loop = (): void => {
      const canvas = this.canvasElementRef.current;
      const video = this.videoElementRef.current;

      if (video) {
        const height =
          this.props.windowSize.height - video.getBoundingClientRect().top;
        if (this.state.height !== height) {
          this.setState({ height });
        }
      }

      if (canvas && video && !video.paused && video.videoHeight !== 0) {
        const ctx = canvas.getContext("2d", { alpha: false });

        if (ctx) {
          const widthScale = canvas.clientWidth / video.videoWidth;
          const heightScale = canvas.clientHeight / video.videoHeight;
          let x = 0;
          let y = 0;
          let renderWidth = canvas.clientWidth;
          let renderHeight = canvas.clientHeight;

          if (video.videoWidth > video.videoHeight) {
            // portrait: 縦幅基準
            x = Math.ceil(
              (video.videoWidth * heightScale - canvas.clientWidth) / -2,
            );
            renderWidth = video.videoWidth * heightScale;
          } else {
            // landscape: 横幅基準
            y = Math.ceil(
              (video.videoHeight * widthScale - canvas.clientHeight) / -2,
            );
            renderHeight = video.videoHeight * widthScale;
          }
          ctx.drawImage(
            video,
            0,
            0,
            video.videoWidth,
            video.videoHeight,
            x,
            y,
            renderWidth,
            renderHeight,
          );

          const rectLength = Math.trunc(
            Math.min(canvas.clientWidth, canvas.clientHeight) / 2,
          );
          ctx.lineWidth = 8;
          ctx.strokeStyle = "rgba(255, 32, 32, 0.8)";
          ctx.strokeRect(
            Math.trunc((canvas.clientWidth - rectLength) / 2),
            Math.trunc((canvas.clientHeight - rectLength) / 2),
            rectLength,
            rectLength,
          );
        }
      }

      this.requestAnimationFrameID = requestAnimationFrame(loop);
    };

    loop();
  }

  componentWillUnmount(): void {
    if (this.requestAnimationFrameID) {
      cancelAnimationFrame(this.requestAnimationFrameID);
      this.requestAnimationFrameID = null;
    }
  }

  render(): React.ReactNode {
    const { videoElementID, width, height, windowSize } = this.props;

    return (
      <div
        style={{
          width,
          height,
          position: "relative",
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "center",
        }}
      >
        <video
          id={videoElementID}
          width={windowSize.width}
          height={this.state.height}
          style={{ position: "absolute", zIndex: -1 }}
          ref={this.videoElementRef}
        />
        <canvas
          width={windowSize.width}
          height={this.state.height}
          style={{
            position: "absolute",
            zIndex: 1,
          }}
          ref={this.canvasElementRef}
        />
      </div>
    );
  }
}

const VideoCanvas = windowSizeHOC()(VideoCanvasBase);
export { VideoCanvas };
