import { Controller } from "@hotwired/stimulus";

const POSITION_FIELD_SELECTOR = ".position_field";

/** 複数 form があるリストを増減できるようにする。 */
export default class extends Controller {
  static targets = ["item", "template", "parent"];

  add(event) {
    const [response, ,] = event.detail;

    // 最後の form の保存ができた時だけ追加する。
    if (response.errors) {
      return;
    }
    const max = parseInt(this.data.get("max"), 10);
    const itemCount = this.itemTargets.length;
    if (itemCount == max) {
      return;
    }
    const lastItem = this.itemTargets[itemCount - 1];
    if (!lastItem.contains(event.target)) {
      return;
    }

    const item = document.importNode(this.templateTarget.content, true);

    const positionField = item.querySelector(POSITION_FIELD_SELECTOR);
    positionField.value = this.itemTargets.length;

    this.parentTarget.appendChild(item);
  }

  remove(event) {
    const removingItem = this.itemTargets.find((x) => x.contains(event.target));
    const remainingItems = this.itemTargets.filter(
      (x) => !x.contains(event.target),
    );
    removingItem.remove();

    for (let position = 0; position < remainingItems.length; position++) {
      const item = remainingItems[position];
      for (const positionField of item.querySelectorAll(
        POSITION_FIELD_SELECTOR,
      )) {
        positionField.value = position;
      }
    }
  }
}
